import { pages } from "app/provider/RootStoreProvider";

export const containerTabletState = (
  page: string,
  isWide: boolean,
  isWidePhone: boolean
) => {
  switch (page) {
    case "start": {
      return {
        right: "50%",
        bottom: "15%",
        y: "50%",
        x: "50%",
        opacity: 0.5,
        scale: 0.7,
      };
    }

    case "wamShortDescription": {
      return {
        right: "50%",
        bottom: "50%",
        y: "55%",
        x: "50%",
        opacity: 1,
        scale: 0.6,
      };
    }
    case "wamLongDescription": {
      return {
        right: "50%",
        bottom: "125%",
        y: "50%",
        x: "50%",
        opacity: 0,
        scale: 0.6,
      };
    }
    case "terminalPresentation": {
      return {
        right: "100%",
        bottom: "120%",
        y: "50%",
        x: "50%",
        opacity: 0,
        scale: 0.6,
      };
    }
    case "lights": {
      return {
        right: "160%",
        bottom: "50%",
        y: "50%",
        x: "50%",
        opacity: 1,
        scale: 0.6,
      };
    }
    case "tabletLeftInfoOne": {
      return {
        right: "62%",
        bottom: "50%",
        y: "50%",
        x: "0%",
        scale: isWide ? 0.85 : 1,
      };
    }
    case "tabletLeftInfoTwo": {
      return {
        right: "62%",
        bottom: "50%",
        y: "50%",
        x: "0%",
        scale: isWide ? 0.85 : 1,
      };
    }
    case "tabletLeftInfoThree": {
      return {
        right: "62%",
        bottom: "50%",
        y: "50%",
        x: "0%",
        scale: isWide ? 0.85 : 1,
      };
    }
    case "tabletRightInfoOne": {
      return {
        right: "-7%",
        bottom: "50%",
        y: "50%",
        x: "0%",
        scale: isWide ? 0.85 : 1,
      };
    }
    case "tabletRightInfoTwo": {
      return {
        right: "-7%",
        bottom: "50%",
        y: "50%",
        x: "0%",
        scale: isWide ? 0.85 : 1,
      };
    }
    case "tabletRightInfoThree": {
      return {
        right: "-7%",
        bottom: "50%",
        y: "50%",
        x: "0%",
        scale: isWide ? 0.85 : 1,
      };
    }

    case "tabletFunctions": {
      return {
        right: `50%`,
        bottom: isWidePhone ? "40%" : "32%",
        y: "50%",
        x: `50%`,
        scale: isWidePhone ? 0.74 : isWide ? 0.75 : 0.85,
      };
    }

    case "tabletBottomInfo": {
      return {
        right: `50%`,
        bottom: isWidePhone ? "-1%" : "10%",
        y: "50%",
        x: `50%`,
        scale: isWidePhone ? 0.74 : isWide ? 0.75 : 0.85,
      };
    }

    default: {
      return {
        right: "50%",
        bottom: "100%",
        x: "50%",
        y: "0%",
        scale: isWide ? 0.75 : 0.85,
      };
    }
  }
};

export const containerTabletStatePortrait = (page: string) => {
  switch (page) {
    default: {
      return {
        right: "50%",
        bottom: "100%",
        x: "50%",
        y: "0%",
        scale: 0.85,
      };
    }
  }
};

export const tabletStates = (page: string) => {
  switch (page) {
    case "tabletLeftInfoOne": {
      return {
        rotateY: 0,
        originX: 0,
      };
    }

    case "tabletLeftInfoTwo": {
      return {
        rotateY: 6,
        originX: 0,
      };
    }

    case "tabletLeftInfoThree": {
      return {
        rotateY: 12,
        originX: 0,
      };
    }
    case "tabletRightInfoOne": {
      return {
        rotateY: 0,
      };
    }

    case "tabletRightInfoTwo": {
      return {
        originX: 0.5,
        rotateY: -6,
      };
    }
    case "tabletRightInfoThree": {
      return {
        originX: 0.5,
        rotateY: -12,
      };
    }
    case "tabletFunctions": {
      return {
        originX: 0.5,
      };
    }

    case "tabletBottomInfo": {
      return {
        originY: 0.5,
        originX: 0.5,
      };
    }

    default: {
      return {};
    }
  }
};

export const techFuncOverlay = (page: string, isPortrait: boolean) => {
  const index = pages.findIndex((item) => item.name === "tabletFunctions");
  switch (page) {
    case pages[index - 1].name: {
      return {
        opacity: 0,
        originX: isPortrait ? 0 : 0.5,
        originY: isPortrait ? 0 : 0.5,
        scale: isPortrait ? 1.8 : 1,
      };
    }
    case pages[index].name: {
      return {
        opacity: 1,
        originX: isPortrait ? 0 : 0.5,
        originY: isPortrait ? 0 : 0.5,
        scale: isPortrait ? 1.8 : 1,
        x: isPortrait ? "-10px" : 0,
        y: isPortrait ? "-15px" : 0,
      };
    }

    case pages[index + 1].name: {
      return {
        opacity: 0,
        originX: isPortrait ? 1 : 0.5,
        originY: isPortrait ? 1 : 0.5,
        scale: isPortrait ? 1.7 : 1,
        x: isPortrait ? "5px" : 0,
        y: isPortrait ? "5px" : 0,
      };
    }
    default: {
      return {
        opacity: 0,
        originX: 0.5,
        originY: 0.5,
        scale: 1,
      };
    }
  }
};
