import { createPortal } from "react-dom";
import { useState, useLayoutEffect } from "react";

interface IProps {
  children: any;
  wrapperId: string;
}

export const Modal = ({ children, wrapperId = "root" }: IProps) => {
  const [wrapperElement, setWrapperElement] = useState<HTMLElement | null>(
    null
  );

  useLayoutEffect(() => {
    let element = document.getElementById(wrapperId);

    if (!element) {
      const wrapperElement = document.createElement("div");
      wrapperElement.setAttribute("id", wrapperId);
      document.body.appendChild(wrapperElement);
      element = wrapperElement;
    }

    setWrapperElement(element);
  }, [wrapperId]);

  if (wrapperElement === null) return null;

  return createPortal(children, wrapperElement);
};
