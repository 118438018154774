import React, { useState } from "react";
import styles from "./ListItem.module.css";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";

interface IProps {
  item: {
    id: number;
    title: string[];
    icon: string;
    description: string;
  };
}

const ListItem = ({ item }: IProps) => {
  const [isOpen, setOpen] = useState(false);

  const handleToggleOpen = () => {
    setOpen(!isOpen);
  };

  return (
    <li className={styles.container__list__item}>
      <div className={styles.container__list__item__icon}>
        <img
          src={item.icon}
          alt={item.title.join("")}
          className={styles.container__list__item__icon__img}
        />
      </div>
      <div className={styles.container__list__item__title}>
        {item.title.map((title) => (
          <p key={title}>{title}</p>
        ))}
      </div>
      <div className={styles.container__list__item__description}>
        <div className={styles.container__list__item__description__action}>
          <div
            className={
              styles.container__list__item__description__action__button
            }
            onClick={handleToggleOpen}
          >
            {isOpen ? (
              <AiOutlineMinus size={24} />
            ) : (
              <AiOutlinePlus size={24} />
            )}
          </div>
          <span className={styles.container__list__item__description__text}>
            Dowiedz sie więcej
          </span>
        </div>
        {isOpen && (
          <div className={styles.container__list__item__description__expanded}>
            <p
              className={
                styles.container__list__item__description__expanded__text
              }
            >
              {item.description}
            </p>
          </div>
        )}
      </div>
    </li>
  );
};

export default ListItem;
