import React from "react";
import styles from "./TerminalPresentation.module.css";
import TabletTwo from "assets/images/tablet2.png";
import TabletTwo2560w from "assets/images/tabletTwo2560w.png";
import TabletTwo1920w from "assets/images/tabletTwo1920w.png";
import TabletTwo1366w from "assets/images/tabletTwo1366w.png";
import TabletTwo1024w from "assets/images/tabletTwo1024w.png";
import TabletTwo768w from "assets/images/tabletTwo768w.png";
import TabletTwo600w from "assets/images/tabletTwo600w.png";
import TabletThree from "assets/images/tabletThree.png";
import TabletThree1920w from "assets/images/tabletThree1920w.png";
import TabletThree1366w from "assets/images/tabletThree1366w.png";
import TabletThree1024w from "assets/images/tabletThree1024w.png";
import TabletThree768w from "assets/images/tabletThree768w.png";
import TabletThree600w from "assets/images/tabletThree600w.png";
import { ReactComponent as WamLogo } from "assets/svgs/wamColor.svg";
import cx from "classnames";
import { motion } from "framer-motion";
import { useAppStore } from "app/provider/RootStoreProvider";
import { findPages } from "app/utils/render";

const PAGES = findPages("terminalPresentation", 1, 1);

const TerminalPresentation = () => {
  const { state } = useAppStore();
  const textAnimates = (state: string) => {
    switch (state) {
      case PAGES.currentPages[0].name:
        return { opacity: 1 };
      default:
        return { opacity: 0 };
    }
  };

  const leftPhotoAnimates = (state: string) => {
    switch (state) {
      case PAGES.currentPages[0].name:
        return { x: 0, y: 0, rotateZ: -20, opacity: 1 };
      default:
        return { x: -50, y: 50, rotateZ: -20, opacity: 0 };
    }
  };

  const rightPhotoAnimates = (state: string) => {
    switch (state) {
      case PAGES.currentPages[0].name:
        return { x: 0, y: 0, opacity: 1 };
      default:
        return { x: 50, y: 50, opacity: 0 };
    }
  };

  const transition = {
    bounce: 0,
    type: "spring",
    damping: 50,
    stiffness: 95,
  };

  const render = PAGES.allPages.find((item) => item.name === state.page);
  if (!render) return null;

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <motion.div
          className={cx(styles.photoContainer, styles.photoLeft)}
          animate={leftPhotoAnimates(state.page)}
          initial={{ opacity: 0, x: -50, y: 50, rotateZ: -20 }}
          transition={transition}
        >
          <div className={cx(styles.photoOverlay, styles.photoOverlayLeft)} />
          <img
            src={TabletTwo}
            sizes="30vw"
            srcSet={`${TabletTwo} 3352w, ${TabletTwo2560w} 2560w, ${TabletTwo1920w} 1920w, ${TabletTwo1366w} 1366w, ${TabletTwo1024w} 1024w, ${TabletTwo768w} 768w, ${TabletTwo600w} 600w`}
            alt="tablet"
            className={styles.photo}
          />
        </motion.div>
        <motion.div
          className={cx(styles.photoContainer, styles.photoRight)}
          transition={transition}
          initial={{ opacity: 0, x: 50, y: 50 }}
          animate={rightPhotoAnimates(state.page)}
        >
          <div className={cx(styles.photoOverlay, styles.photoOverlayRight)} />
          <img
            src={TabletThree}
            alt="tablet"
            sizes="30vw"
            srcSet={`${TabletThree} 2587w, ${TabletThree1920w} 1920w, ${TabletThree1366w} 1366w, ${TabletThree1024w} 1024w, ${TabletThree768w} 768w, ${TabletThree600w} 600w`}
            className={styles.photo}
          />
        </motion.div>
      </div>
      <motion.div
        className={styles.content}
        initial={{ opacity: 0 }}
        animate={textAnimates(state.page)}
      >
        <div className={styles.center}>
          <div className={styles.logo}>
            <WamLogo />
          </div>
          <h2 className={styles.title}>
            Zwiększ poziom <br />{" "}
            <b className={styles.titleBold}>bezpieczeństwa</b>
          </h2>
          <p className={styles.description}>
            Od 2020 roku w Polsce ilość zdarzeń kolejowych wzrosła o 27%.
            <br />
            Wybierz <b>WAM</b> i zadbaj o bezpieczeństwo swojej floty już dziś!
          </p>
        </div>
      </motion.div>
    </div>
  );
};

export default TerminalPresentation;
