import { findPages } from "app/utils/render";

export const PAGES = findPages("start", 1, 1);
export const textStates = (page: string) => {
  switch (page) {
    case PAGES.currentPages[0].name: {
      return {
        top: `30%`,
        y: "-50%",
        x: "-50%",
        left: "50%",
      };
    }
    case PAGES.nextPages[0].name: {
      return {
        top: `-40%`,
        y: "-50%",
        x: "-50%",
        left: "50%",
      };
    }
    default: {
      return {
        top: "-40%",
        y: "-50%",
        x: "-50%",
        left: "50%",
      };
    }
  }
};

export const textStatesPortrait = (page: string) => {
  switch (page) {
    case PAGES.currentPages[0].name: {
      return {
        top: "20%",
        left: "50%",
        y: 0,
      };
    }
    case PAGES.nextPages[0].name: {
      return {
        top: "20%",
        left: "50%",
        y: -window.innerHeight / 2,
        opacity: 0,
      };
    }
    default: {
      return {
        top: "20%",
        left: "50%",
        y: -window.innerHeight / 2,
      };
    }
  }
};

export const buttonStates = (page: string) => {
  switch (page) {
    case PAGES.currentPages[0].name: {
      return {
        opacity: 1,
      };
    }
    case PAGES.nextPages[0].name: {
      return {
        opacity: 0,
      };
    }
    default: {
      return {
        opacity: 0,
      };
    }
  }
};

export const buttonStatesPortrait = (page: string) => {
  switch (page) {
    case PAGES.currentPages[0].name: {
      return {
        opacity: 1,
        y: 0,
      };
    }
    case PAGES.nextPages[0].name: {
      return {
        opacity: 0,
        y: -50,
      };
    }
    default: {
      return {
        opacity: 0,
        y: -50,
      };
    }
  }
};

export const initalState = (pageName: string, isPortrait: boolean) => {
  if (isPortrait) {
    if (pageName === PAGES.currentPages[0].name) {
      return {
        top: "20%",
        left: "50%",
        y: "-50%",
      };
    }
    return {
      top: "20%",
      left: "50%",
      y: -window.innerHeight / 2,
    };
  } else if (PAGES.currentPages[0].name === pageName) {
    return {
      top: `30%`,
      y: "-50%",
      x: "-50%",
      left: "50%",
    };
  }
  return {
    top: "-40%",
    y: "-50%",
    left: "10vw",
  };
};

export const initalButtonState = (pageName: string, isPortrait: boolean) => {
  if (isPortrait) {
    if (PAGES.currentPages[0].name === pageName) {
      return {
        opacity: 1,
        y: 0,
      };
    }
    return {
      opacity: 0,
      y: -window.innerHeight / 2,
    };
  } else {
    return {
      opacity: 0,
    };
  }
};

export const transition = {
  bounce: 0,
  type: "spring",
  damping: 50,
  stiffness: 100,
};
