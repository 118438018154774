import Description from "components/Mobile/Description/Description";
import Functions from "components/Mobile/Functions/Functions";
import Graph from "components/Mobile/Graph/Graph";
import Navbar from "components/Mobile/Navbar/Navbar";
import Safety from "components/Mobile/Safety/Safety";
import Start from "components/Mobile/Start/Start";
import Trains from "components/Mobile/Trains/Trains";
import React from "react";
import styles from "./MobileLayout.module.css";
import Presentation from "components/Mobile/Presentation/Presentation";
import Contact from "components/Mobile/Contact/Contact";

const MobileLayout = () => {
  return (
    <div className={styles.mobile}>
      <div className={styles.header}>
        <Navbar />
      </div>
      <div className={styles.content}>
        <Start />
        <Description />
        <Functions />
        <Graph />
        <Safety />
        <Trains />
        <Presentation />
        <Contact />
      </div>
    </div>
  );
};

export default MobileLayout;
