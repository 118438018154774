import React from "react";
import styles from "./Start.module.css";
import startPhoto from "assets/images/start.jpg";
import sphere1500w from "assets/images/sphere1500w.jpg";
import sphere1000w from "assets/images/sphere1000w.jpg";
import sphere700w from "assets/images/sphere700w.jpg";
import { GoPlay } from "react-icons/go";

const Start = () => {
  return (
    <div className={styles.container} id="start">
      <div className={styles.container__start}>
        <div className={styles.container__start__photo}>
          <img
            src={startPhoto}
            alt="tablet"
            className={styles.container__start__photo__img}
          />
        </div>
        <div className={styles.container__start__header}>
          <h2 className={styles.container__start__header__heading}>
            WIRTUALNY ASYSTENT <br /> MASZYNISTY
          </h2>
          <span className={styles.container__start__header__subtext}>
            Z NAWIGATOREM SZLAKU
          </span>
          <div className={styles.container__start__header__demo}>
            <span className={styles.container__start__header__demo__text}>
              ZOBACZ DEMO
            </span>
            <a href="#presentation">
              <div className={styles.container__start__header__demo__button}>
                <GoPlay
                  size={42}
                  className={
                    styles.container__start__header__demo__button__icon
                  }
                />
              </div>
            </a>
          </div>
        </div>
        <div className={styles.container__start__header__buttomText}>
          <p className={styles.container__start__header__buttomText__paragraph}>
            PRZYSZŁOŚĆ SYSTEMU BEZPIECZEŃSTWA
          </p>
          <p className={styles.container__start__header__buttomText__paragraph}>
            DLA TRANSPORTU KOLEJOWEGO
          </p>
        </div>
      </div>
      <div className={styles.spherePhotoContainer}>
        <img
          srcSet={`${sphere1500w} 1500w, ${sphere1000w} 1000w, ${sphere700w} 700w`}
          sizes="100vw"
          src={sphere1500w}
          className={styles.spherePhoto}
          alt="tablet"
        />
      </div>
    </div>
  );
};

export default Start;
