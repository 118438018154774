import React, { useEffect } from "react";
import styles from "./Lights.module.css";
import LightsImage from "assets/images/lights.png";
import { motion } from "framer-motion";
import { useAppStore } from "app/provider/RootStoreProvider";
import { findPages } from "app/utils/render";

const PAGES = findPages("lights", 1, 1);

const Lights = () => {
  const { state } = useAppStore();

  const lightsAnimates = (state: string) => {
    switch (state) {
      case PAGES.currentPages[0].name:
        return {
          scale: 1.1,
          x: "-50%",
          top: "40%",
          opacity: 1,
        };
      case PAGES.nextPages[0].name:
        return {
          opacity: 0,
          scale: 1.1,
        };
      default: {
        return {
          scale: 0.6,
          opacity: 0,
          top: 0,
          x: "-50%",
        };
      }
    }
  };

  const transition = {
    bounce: 0,
    type: "spring",
    damping: 50,
    stiffness: 95,
  };

  useEffect(() => {
    let timeout: any = null;
    if (
      state.page === PAGES.currentPages[0].name &&
      state.scrollDirection === "next"
    ) {
      const evt = document.createEvent("MouseEvents") as any;
      evt.initEvent("wheel", true, true);
      evt.deltaY = 20;
      timeout = setTimeout(() => {
        document.documentElement.dispatchEvent(evt);
      }, PAGES.currentPages[0].break + 200);
    } else if (
      state.page === PAGES.currentPages[0].name &&
      state.scrollDirection === "back"
    ) {
      const evt = document.createEvent("MouseEvents") as any;
      evt.initEvent("wheel", true, true);
      evt.deltaY = -20;
      timeout = setTimeout(() => {
        document.documentElement.dispatchEvent(evt);
      }, PAGES.currentPages[0].break + 200);
    }
    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.page]);

  const render = PAGES.allPages.find((item) => item.name === state.page);

  if (!render) return null;

  return (
    <div className={styles.container}>
      <motion.div
        className={styles.photoContainer}
        animate={lightsAnimates(state.page)}
        transition={transition}
        initial={{
          top: 0,
          scale: 0.6,
          x: "-50%",
          opacity: 0,
        }}
      >
        <img src={LightsImage} alt="światła" className={styles.photo} />
      </motion.div>
    </div>
  );
};

export default Lights;
