import { useAppStore, pages } from "app/provider/RootStoreProvider";
import React, { useCallback, useEffect, useRef } from "react";
import Background from "components/Desktop/Main/Background/Background";
import Stripes from "components/Desktop/Main/Stripes/Stripes";
import Tablet from "components/Desktop/Main/Tablet/Tablet";
import ScreenOne from "components/Desktop/Screens/Start/Start";
import WamShortDescription from "components/Desktop/Screens/WamShortDescription/WamShortDescription";
import WamLongDescription from "components/Desktop/Screens/WamLongDescription/WamLongDescription";
import TerminalPresentation from "components/Desktop/Screens/TerminalPresentation/TerminalPresentation";
import Lights from "components/Desktop/Screens/Lights/Lights";
import TabletLeftInfoOne from "components/Desktop/Screens/TabletLeftInfoOne/TabletLeftInfoOne";
import TabletLeftInfoTwo from "components/Desktop/Screens/TabletLeftInfoTwo/TabletLeftInfoTwo";
import TabletLeftInfoThree from "components/Desktop/Screens/TabletLeftInfoThree/TabletLeftInfoThree";
import TabletRightInfoOne from "components/Desktop/Screens/TabletRightInfoOne/TabletRightInfoOne";
import TabletRightInfoTwo from "components/Desktop/Screens/TabletRightInfoTwo/TabletRightInfoTwo";
import TabletRightInfoThree from "components/Desktop/Screens/TabletRightInfoThree/TabletRightInfoThree";
import Technology from "components/Desktop/Screens/Technology/Technology";
import ProductSlider from "components/Desktop/Screens/ProductSlider/ProductSlider";
import BreakingCurve from "components/Desktop/Screens/BreakingCurve/BreakingCurve";
import Video from "components/Desktop/Screens/Video/Video";
import Contact from "components/Desktop/Screens/Contact/Contact";

const DesktopLayout = () => {
  const { dispatch, state } = useAppStore();

  const containerRef = useRef<HTMLDivElement>(null);
  const isPageTransitioning = useRef<any>(false);
  const currentPage = useRef<number>(state.pageN);

  const onScroll = useCallback((scroll: WheelEvent) => {
    if (isPageTransitioning.current) return;
    const deltaY = scroll.deltaY;
    const operationType = deltaY > 0;
    let breakPoint;
    if (operationType)
      breakPoint = pages[currentPage.current + 1]?.break || 1000;
    else breakPoint = pages[currentPage.current - 1]?.break || 1000;
    isPageTransitioning.current = true;

    setTimeout(() => {
      isPageTransitioning.current = false;
    }, breakPoint);

    if (operationType) dispatch({ type: "nextPage" });
    else {
      dispatch({ type: "previousPage" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.addEventListener("wheel", onScroll);
    return () => {
      document.removeEventListener("wheel", onScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    currentPage.current = state.pageN;
  }, [state.pageN]);
  return (
    <div className="desktop">
      <Background containerRef={containerRef}>
        <ScreenOne />
        <WamShortDescription />
        <WamLongDescription />
        <TerminalPresentation />
        <Lights />
        <TabletLeftInfoOne />
        <TabletLeftInfoTwo />
        <TabletLeftInfoThree />
        <TabletRightInfoOne />
        <TabletRightInfoTwo />
        <TabletRightInfoThree />
        <BreakingCurve />
        <Technology />
        <ProductSlider />
        <Tablet />
        <Stripes />

        <Video />
        <Contact />
      </Background>
    </div>
  );
};

export default DesktopLayout;
