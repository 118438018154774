import styles from "./Video.module.css";
import { useAppStore } from "app/provider/RootStoreProvider";
import { GoPlay } from "react-icons/go";
import tablet4k from "assets/images/tablet4k.png";
import tablet2k from "assets/images/tablet2k.png";
import tablet1920w from "assets/images/tablet1920w.png";
import tablet1366w from "assets/images/tablet1366w.png";
import tablet1024w from "assets/images/tablet1024w.png";
import video from "assets/demo_wam.mp4";
import { motion } from "framer-motion";
import { findPages } from "app/utils/render";
import { useState } from "react";
import { Modal } from "./Modal";

const PAGES = findPages("video", 1, 1);

const Video = () => {
  const { state } = useAppStore();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const tabletStates = (page: string) => {
    switch (page) {
      case PAGES.prevPages[0].name: {
        return {
          opacity: 0,
        };
      }

      case PAGES.currentPages[0].name: {
        return {
          opacity: 1,
        };
      }
      default: {
        return {
          opacity: 0,
          rotateX: 20,
        };
      }
    }
  };

  const tableMoveState = (page: string) => {
    switch (page) {
      case PAGES.prevPages[0].name: {
        return {
          y: "0",
        };
      }

      case PAGES.currentPages[0].name: {
        return {
          y: "-5vh",
        };
      }
      default: {
        return {
          y: "0",
        };
      }
    }
  };
  const headingStates = (page: string) => {
    switch (page) {
      case PAGES.prevPages[0].name: {
        return {
          y: 0,
          opacity: 0,
        };
      }

      case PAGES.currentPages[0].name: {
        return {
          y: 0,
          opacity: 1,
        };
      }
      default: {
        return {
          opacity: 0,
          y: 0,
        };
      }
    }
  };

  const transition = {
    bounce: 0,
    type: "spring",
    damping: 30,
  };

  const render = PAGES.allPages.find((item) => item.name === state.page);
  if (!render) return null;

  return (
    <>
      {isModalOpen && (
        <Modal wrapperId="root">
          <div className={styles.modalWrap} onClick={closeModal}>
            <div className="modal" onClick={(e) => e.stopPropagation()}>
              <video controls width="100%" height="100%" autoPlay>
                <source src={video} type="video/mp4" />
              </video>
            </div>
          </div>
        </Modal>
      )}
      <motion.div className={styles.container}>
        <div className={styles.titleContainer}>
          <div className={styles.titleContainerItem}>
            <div className={styles.title}>
              <motion.div
                animate={headingStates(state.page)}
                transition={transition}
                initial={{
                  y: 100,
                  opacity: 0,
                }}
              >
                <h1 className={styles.titleText}>BEZPIECZNA PRZYSZŁOŚĆ</h1>
              </motion.div>
              <motion.div
                animate={headingStates(state.page)}
                transition={transition}
                initial={{
                  y: 100,
                  opacity: 0,
                }}
              >
                <h1 className={styles.titleText}>NA WYCIĄGNIĘCIE RĘKI.</h1>
                <div className={styles.titleLine} />
              </motion.div>
            </div>
          </div>
        </div>

        <motion.div
          className={styles.containerItem}
          transition={transition}
          animate={tableMoveState(state.page)}
        >
          <div className={styles.tabletPerspective}>
            <motion.div
              className={styles.tablet}
              animate={tabletStates(state.page)}
              transition={transition}
              initial={{
                opacity: 0,
              }}
            >
              <div className={styles.playButton} onClick={openModal}>
                <GoPlay className={styles.playIcon} />
                <p className={styles.playButtonText}>
                  OBEJRZYJ PREZENTACJE <br /> SYSTEMU!
                </p>
              </div>
              <img
                srcSet={`${tablet4k} 2800w, ${tablet2k} 2000w, ${tablet1920w} 1300w, ${tablet1366w} 1000w, ${tablet1024w} 700w`}
                sizes="48vw"
                src={tablet1920w}
                className={styles.image}
                alt="Wideo na tablecie"
              />
            </motion.div>
          </div>
        </motion.div>
      </motion.div>
    </>
  );
};

export default Video;
