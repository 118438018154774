import { useAppStore } from "app/provider/RootStoreProvider";
import { findSection } from "app/utils/render";
import { motion } from "framer-motion";
import React from "react";
import styles from "./styles/Header.module.css";
import { navColor } from "./utils";

const PAGES = findSection("sectionOne");

const SystemSubNav = () => {
  const { state, dispatch } = useAppStore();
  return (
    <nav
      role="navigation"
      aria-labelledby="second nav"
      className={styles.subNav}
    >
      <ul className={styles.subNavItems}>
        {PAGES.map((item) =>
          item.name === "lights" ? (
            ""
          ) : (
            <li className={styles.subNavItem} key={item.name}>
              <motion.button
                animate={{
                  color: navColor(state.page === item.name),
                }}
                transition={{
                  duration: 0.3,
                  ease: "easeInOut",
                }}
                className={styles.subNavItemLink}
                onClick={() =>
                  dispatch({
                    type: "setPage",
                    payload: { pageName: item.name },
                  })
                }
                aria-label={`Przejdź do ${item.name}`}
              >
                {item.title}
              </motion.button>
            </li>
          )
        )}
      </ul>
    </nav>
  );
};

export default SystemSubNav;
