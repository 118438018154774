import React from "react";
import styles from "./BreakingCurve.module.css";
import stripes from "assets/images/breakingCurveStripes.png";
import train from "assets/images/breakingCurveTrain.png";
import { useAppStore } from "app/provider/RootStoreProvider";
import { motion } from "framer-motion";
import { findPages } from "app/utils/render";

const PAGES = findPages("breakingCurve", 1, 1);

const BreakingCurve = () => {
  const { state } = useAppStore();

  const opacityAnimates = (page: string) => {
    switch (page) {
      case PAGES.prevPages[0].name: {
        return {
          opacity: 0,
        };
      }
      case PAGES.currentPages[0].name: {
        return {
          opacity: 1,
        };
      }
      default: {
        return {
          opacity: 0,
        };
      }
    }
  };

  const moveAnimates = (page: string) => {
    switch (page) {
      case PAGES.prevPages[0].name: {
        return {
          x: 200,
          opacity: 0,
          scale: 0.6,
        };
      }
      case PAGES.currentPages[0].name: {
        return {
          x: 0,
          scale: 1,
          opacity: 1,
        };
      }
      default: {
        return {
          x: 50,
        };
      }
    }
  };

  const transition = {
    bounce: 0,
    type: "spring",
    damping: 50,
    stiffness: 100,
  };

  const render = PAGES.allPages.find((item) => item.name === state.page);
  if (!render) return null;
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.background}>
          <motion.div
            className={styles.backgroundStripes}
            transition={transition}
            initial={{ opacity: 0 }}
            animate={opacityAnimates(state.page)}
          >
            <img
              src={stripes}
              alt="kolorowe paski"
              className={styles.backgroundStripesImg}
            />
          </motion.div>
          <motion.div
            className={styles.backgroundTrain}
            transition={transition}
            initial={{ x: 50, opacity: 0 }}
            animate={moveAnimates(state.page)}
          >
            <img
              src={train}
              alt="pociag"
              className={styles.backgroundTrainImg}
            />
          </motion.div>
        </div>
        <motion.div
          className={styles.text}
          transition={transition}
          initial={{ opacity: 0 }}
          animate={opacityAnimates(state.page)}
        >
          <h2 className={styles.title}>
            ANALIZA I WYZNACZANIE <br /> KRZYWEJ HAMOWANIA
          </h2>
          <p className={styles.description}>
            Moduł wsparcia procesu hamowania i przyspieszania, dynamicznie
            wyświetla wzorcową krzywą hamowania w zależności od rodzaju pojazdu
            trakcyjnego, aktualnej prędkości, stanu taboru i infrastruktury,
            warunków pogodowych. System również prezentuje na kokpicie WAM
            krzywą eksploatacyjną, która jest efektem rejstracji różnych
            charakterystyk hamowania maszynistów, również przy uwzględnieniu
            tych samych warunków jazdy. Moduł wspiera również maszynistę w
            precyzyjnym zatrzymaniu pojazdu trakcyjnego w obrębie stacji.
          </p>
        </motion.div>
      </div>
    </div>
  );
};

export default BreakingCurve;
