import { useAppStore } from "app/provider/RootStoreProvider";
import { findPages } from "app/utils/render";
import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import styles from "./WamShortDescription.module.css";

const PAGES = findPages("wamShortDescription", 1, 1);

const textVariants = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: {
      delay: 0.5,
      duration: 1,
    },
  },
  exit: {
    opacity: 0,
  },
};

const WamShortDescription = () => {
  const { state } = useAppStore();
  const render = PAGES.allPages.find((item) => item.name === state.page);
  if (!render) return null;
  return (
    <AnimatePresence>
      {state.page === PAGES.currentPages[0].name ? (
        <div className={styles.container}>
          <motion.div
            className={styles.title}
            variants={textVariants}
            initial="initial"
            animate="animate"
            exit="exit"
          >
            <h2 className={styles.titleText}>
              Zaprojektowany <br />
              <span className={styles.titleTextThin}>
                Z myślą o bezpieczeństwie
              </span>
            </h2>
          </motion.div>
          <motion.div
            className={styles.text}
            variants={textVariants}
            initial="initial"
            animate="animate"
            exit="exit"
          >
            <p className={styles.textParagraph}>
              Jest innowacyjnym rozwiązaniem wspierającym pracę maszynisty.
            </p>
            <p className={styles.textParagraph}>
              Wyznacza nowy standard wśród systemów wspierających kierowanie
              pojazdem.
            </p>
            <p className={styles.textParagraph}>
              Podnosi poziom bezpieczeństwa i zwiększa efektywność w zarządzaniu
              informacjami ze szlaku kolejowego.
            </p>
            <p className={styles.textParagraph}>
              Dostarcza informacji o przebiegu trasy, elementach infrastruktury,
              wskaźnikach.
            </p>
            <p className={styles.textParagraph}>
              Umożliwia wymianę informacji między kierującymi.
            </p>
          </motion.div>
        </div>
      ) : null}
    </AnimatePresence>
  );
};

export default WamShortDescription;
