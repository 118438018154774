import React, { useEffect, useRef, useState } from "react";
import { useAppStore } from "app/provider/RootStoreProvider";
import styles from "./styles/Stripes.module.css";
import { motion } from "framer-motion";
import { stripesStates, stripesStatesPortrait } from "./utils";
import stripes from "assets/images/stripes.png";
import stripes1366w from "assets/images/stripes1366w.png";
import stripes1024w from "assets/images/stripes1024w.png";
import stripes768w from "assets/images/stripes768w.png";
import stripes600w from "assets/images/stripes600w.png";

const Stripes = () => {
  const { state } = useAppStore();
  const containerRef = useRef<HTMLDivElement>(null);
  const [animates, setAnimates] = useState<any>({});

  const transition = {
    bounce: 0,
    type: "spring",
    damping: 30,
  };

  const inital = {
    top: state.pageN === 0 ? "50%" : "65%",
    right: state.pageN === 0 ? "-20%" : "100%",
    y: "-50%",
  };

  useEffect(() => {
    let nextState;
    if (!state.isPortrait) nextState = stripesStates(state.page);
    else nextState = stripesStatesPortrait(state.page);
    setAnimates(nextState);
  }, [state.page, state.isPortrait]);

  return (
    <motion.div
      className={styles.container}
      transition={transition}
      initial={inital}
      animate={animates}
      ref={containerRef}
    >
      <img
        src={stripes}
        alt="kolorowe paski"
        sizes="120vw"
        srcSet={`${stripes} 1920w, ${stripes1366w} 1366w, ${stripes1024w} 1024w, ${stripes768w} 768w, ${stripes600w} 600w`}
      />
    </motion.div>
  );
};

export default Stripes;
