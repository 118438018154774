/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from "react";

interface IProps {
  containerRef: React.RefObject<HTMLElement>;
  handlePrevious: () => void;
  handleNext: () => void;
}

const useSlide = ({ containerRef, handlePrevious, handleNext }: IProps) => {
  const [previousFuncFires, setPreviousFuncFires] = useState(0);
  const [nextFuncFires, setnextFuncFires] = useState(0);

  const touchStart = useRef(0);
  const handleTouchStart = useCallback((e) => {
    touchStart.current = e.changedTouches[0].pageX;
  }, []);

  const handleTouchEnd = useCallback((e) => {
    const touchLength = touchStart.current - e.changedTouches[0].pageX;
    const operation = touchLength < 0 ? 1 : 0;
    const absValue = Math.abs(touchLength);

    if (absValue < 100) return;
    if (operation) {
      setPreviousFuncFires((prev) => prev + 1);
    } else {
      setnextFuncFires((prev) => prev + 1);
    }
  }, []);

  useEffect(() => {
    if (previousFuncFires > 0) handlePrevious();
  }, [previousFuncFires]);

  useEffect(() => {
    if (nextFuncFires > 0) handleNext();
  }, [nextFuncFires]);

  useEffect(() => {
    if (!containerRef.current) return;
    containerRef.current.addEventListener("touchstart", handleTouchStart);

    containerRef.current.addEventListener("touchend", handleTouchEnd);
  }, []);
  return [];
};

export default useSlide;
