export const stripesStates = (page: string) => {
  switch (page) {
    case "start": {
      return {
        right: "0%",
        top: "50%",
        scale: 0.75,
        rotateZ: -2,
      };
    }
    case "wamShortDescription": {
      return {
        right: "0%",
        top: "50%",
        scale: 0.75,
        rotateZ: -2,
      };
    }
    case "wamLongDescription": {
      return {
        right: "-100%",
        top: "50%",
        scale: 0.75,
        rotateZ: -2,
      };
    }
    case "terminalPresentation": {
      return {
        right: "0%",
        top: "50%",
        scale: 0.75,
        rotateZ: -2,
      };
    }
    case "lights": {
      return {
        right: "-100%",
        top: "50%",
        scale: 0.75,
        rotateZ: -2,
      };
    }
    case "tabletLeftInfoOne": {
      return {
        right: "40%",
        top: "55%",
        scale: 0.75,
        rotateZ: -2,
      };
    }
    case "tabletLeftInfoTwo": {
      return {
        right: "0%",
        top: "55%",
        scale: 0.75,
        rotateZ: -2,
      };
    }
    case "tabletLeftInfoThree": {
      return {
        right: "-100%",
        top: "55%",
        opacity: 0,
        scale: 0.75,
        rotateZ: -2,
      };
    }

    case "tabletRightInfoOne": {
      return {
        right: "100%",
        top: "55%",
        scale: 0.75,
        opacity: 0,
        rotateZ: -2,
      };
    }

    case "tabletRightInfoTwo": {
      return {
        right: "0%",
        top: "55%",
        scale: 0.75,
        opacity: 1,
        rotateZ: -2,
      };
    }

    case "tabletRightInfoThree": {
      return {
        right: "-100%",
        top: "50%",
        scale: 0.75,
        opacity: 1,
        rotateZ: -2,
      };
    }

    case "tabletFunctions": {
      return {
        right: "-100%",
        top: "50%",
        scale: 0.75,
        rotateZ: -2,
      };
    }
    case "reviews": {
      return {
        right: "0%",
        top: "50%",
        scale: 0.75,
        rotateZ: -2,
      };
    }

    case "contact": {
      return {
        right: "20%",
        top: "50%",
        scale: 0.75,
        rotateZ: -2,
      };
    }

    default: {
      return {
        right: "100%",
        top: "65%",
        scale: 0.75,
        rotateZ: -2,
      };
    }
  }
};

export const stripesStatesPortrait = (page: string) => {
  switch (page) {
    default: {
      return {
        right: "100%",
        top: "65%",
        scale: 0.9,
        rotateZ: -2,
      };
    }
  }
};
