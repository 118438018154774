import { findPages } from "app/utils/render";

export const pages = findPages("tabletFunctions", 1, 1);
export const markerStates = (page: string) => {
  switch (page) {
    case pages.prevPages[0].name: {
      return {
        opacity: 0,
      };
    }
    case pages.currentPages[0].name: {
      return {
        opacity: 1,
      };
    }
  }
};

export const markerStatesPortrait = (page: string) => {
  switch (page) {
    case pages.prevPages[0].name: {
      return {
        opacity: 0,
        y: "30vh",
        x: "-50%",
      };
    }

    case pages.currentPages[0].name: {
      return {
        opacity: 1,
        y: 0,
        x: "-50%",
      };
    }
    case pages.nextPages[0].name: {
      return {
        opacity: 0,
        y: "-30vh",
        x: "-50%",
      };
    }
  }
};
