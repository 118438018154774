import React from "react";
import styles from "./Presentation.module.css";
import tablet from "assets/images/tablet1920w.jpg";
import HandsBig from "assets/images/handsBig.jpg";
import HandsMedium from "assets/images/handsMedium.jpg";
import HandsSmall from "assets/images/handsSmall.jpg";
import video from "assets/demo_wam.mp4";

const Presentation = () => {
  return (
    <>
      <div className={styles.container} id="presentation">
        <div className={styles.container__overlay} />
        <div className={styles.container__background}>
          <img
            srcSet={`${HandsBig} 1200w, ${HandsMedium} 700w, ${HandsSmall} 400w`}
            sizes="100vw"
            alt="dłonie w tle"
            className={styles.container__background__image}
          />
        </div>
        <div className={styles.container__content}>
          <div className={styles.container__content__title}>
            <h2 className={styles.container__content__title__heading}>
              BEZPIECZNA PRZYSZŁOŚĆ <br /> NA WYCIĄGNIĘCIE RĘKI
            </h2>
          </div>
          <div className={styles.container__content__tablet}>
            <img
              src={tablet}
              alt="tablet"
              className={styles.container__content__tablet__image}
            />
          </div>
        </div>
        <div className={styles.container__content__tablet__overlay}>
          {/* <div
          className={styles.container__content__tablet__overlay__iconCotainer}
        >
          <GoPlay
            className={
              styles.container__content__tablet__overlay__iconContainer__icon
            }
          />
        </div> */}
          <div className={styles.container__content__tablet__overlay__text}>
            <p
              className={
                styles.container__content__tablet__overlay__text__pragraph
              }
            >
              OBEJRZYJ PREZENTACJE
            </p>
            <p
              className={
                styles.container__content__tablet__overlay__text__pragraph
              }
            >
              SYSTEMU!
            </p>
          </div>
        </div>
      </div>
      <div id="video-wrap">
        <video controls width="100%" height="100%">
          <source src={video} type="video/mp4" />
        </video>
      </div>
    </>
  );
};

export default Presentation;
