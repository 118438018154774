import { pages } from "app/provider/RootStoreProvider";

const findPageName = (sectionName: string) => {
  return pages.find((item) => item.friendlyName === sectionName)?.name;
};
export const mainNavClick = (section: string, dispatch: any) => {
  if (section === "start") return;
  dispatch({ type: "setPage", payload: { pageName: findPageName("start") } });
};

export const systemNavClick = (section: string, dispatch: any) => {
  if (section === "sectionOne") return;
  dispatch({
    type: "setPage",
    payload: { pageName: findPageName("sectionOne") },
  });
};

export const technologyNavClick = (section: string, dispatch: any) => {
  if (section === "sectionTwo") return;
  dispatch({
    type: "setPage",
    payload: { pageName: findPageName("sectionTwo") },
  });
};

export const productNavClick = (section: string, dispatch: any) => {
  if (section === "sectionThree") return;
  dispatch({
    type: "setPage",
    payload: { pageName: findPageName("sectionThree") },
  });
};

export const contactNavClick = (section: string, dispatch: any) => {
  if (section === "sectionFour") return;
  dispatch({
    type: "setPage",
    payload: { pageName: findPageName("sectionFour") },
  });
};
