import React, { useRef, useState } from "react";
import train from "assets/images/train.png";
import train4k from "assets/images/train4k.png";
import train2k from "assets/images/train2k.png";
import train1920w from "assets/images/train1920w.png";
import train1366w from "assets/images/train1366w.png";
import train1024w from "assets/images/train1024w.png";
import styles from "./Trains.module.css";
import SliderItem from "./SliderItem";
import useSlide from "../hooks/useSlide";
import Train1 from "assets/images/train1Small.jpg";
import Train2 from "assets/images/train2.jpg";
import Train3 from "assets/images/train3.jpg";
import Stripes from "assets/images/stripesMobile.jpg";

const elWidth = 300;

const items = [
  {
    id: 1,
    title: "Dynamiczny asystent z funkcją wirtualnego pilota",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Deleniti autem aperiam tempore animi deserunt sequi sed maxime, in neque vero! Optio unde rem placeat magnam impedit porro labore, exercitationem earum.",
    image: Train1,
  },
  {
    id: 2,
    title:
      "Szybkie oznaczanie miejsc na mapie (uszkodzeń, wypadków, incydentów)",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Deleniti autem aperiam tempore animi deserunt sequi sed maxime, in neque vero! Optio unde rem placeat magnam impedit porro labore, exercitationem earum.",
    image: Train2,
  },
  {
    id: 3,
    title:
      "Automatyczna detekcja i identyfikacja wskaźników kolejowych oraz elementów infrastruktury",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Deleniti autem aperiam tempore animi deserunt sequi sed maxime, in neque vero! Optio unde rem placeat magnam impedit porro labore, exercitationem earum.",
    image: Train3,
  },
  {
    id: 4,
    title: "Moduł Eco Driving",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Deleniti autem aperiam tempore animi deserunt sequi sed maxime, in neque vero! Optio unde rem placeat magnam impedit porro labore, exercitationem earum.",
    image: Train1,
  },
  {
    id: 5,
    title: "Cyfrowa biblioteka dokumentów",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Deleniti autem aperiam tempore animi deserunt sequi sed maxime, in neque vero! Optio unde rem placeat magnam impedit porro labore, exercitationem earum.",
    image: Train2,
  },
  {
    id: 6,
    title: "I wiele więcej...",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Deleniti autem aperiam tempore animi deserunt sequi sed maxime, in neque vero! Optio unde rem placeat magnam impedit porro labore, exercitationem earum.",
    image: Train3,
  },
];

const Trains = () => {
  const [sliderPosition, setSliderPosition] = useState(0);
  const containerRef = useRef<HTMLElement>(null);

  const handlePrevious = () => {
    if (sliderPosition === 0) return;
    setSliderPosition((prev) => prev - elWidth);
  };

  const handleNext = () => {
    if (elWidth * (items.length - 1) === sliderPosition) return;
    setSliderPosition((prev) => prev + elWidth);
  };

  useSlide({ containerRef, handlePrevious, handleNext });

  return (
    <section className={styles.container} ref={containerRef}>
      <div className={styles.container__start}>
        <div className={styles.container__start__background}>
          <img
            srcSet={`${train4k} 200w, ${train2k} 1300w, ${train1920w} 1000w, ${train1366w} 700w, ${train1024w} 550w,`}
            sizes="200vw"
            src={train}
            className={styles.container__start__background__image}
            alt="pociąg"
          />
        </div>
      </div>
      <div className={styles.container__slider} id="technology">
        <div className={styles.container__title}>
          <h2 className={styles.container__title__heading}>
            WSZYSTKO <br /> CZEGO POTRZEBUJESZ <br /> W ZASIĘGU RĘKI
          </h2>
        </div>
        <div className={styles.container__items}>
          <div
            className={styles.container__items__container}
            style={{ transform: `translateX(-${sliderPosition}px)` }}
          >
            {items.map((item) => (
              <SliderItem
                key={item.id}
                width={elWidth}
                description={item.description}
                title={item.title}
                image={item.image}
              />
            ))}
          </div>
        </div>
      </div>
      <div className={styles.container__effects}>
        <div className={styles.container__effects__background}>
          <img
            src={Stripes}
            alt="paski"
            className={styles.container__effects__background__img}
          />
        </div>
      </div>
    </section>
  );
};

export default Trains;
