import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from "./Slider.module.css";
import TabletFunc01 from "assets/images/TabletFunc01.jpg";
import TabletFunc02 from "assets/images/TabletFunc02.jpg";
import TabletFunc03 from "assets/images/TabletFunc03.jpg";
import TabletFunc04 from "assets/images/TabletFunc04.jpg";
import TabletFunc05 from "assets/images/TabletFunc05.jpg";

const Slider = () => {
  const [indicatorTransform, setIndicatorTransform] = useState(0);
  const [sliderTransform, setSliderTransform] = useState(0);

  const number = useRef<number>(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const touchStart = useRef(0);

  const handleNext = () => {
    if (number.current === 4) return;
    number.current++;
    setIndicatorTransform((prev) => prev + 100);
    setSliderTransform((prev) => prev - 100);
  };

  const handlePrevious = () => {
    if (number.current === 0) return;
    number.current--;
    setIndicatorTransform((prev) => prev - 100);
    setSliderTransform((prev) => prev + 100);
  };

  const handleTouchStart = useCallback((e) => {
    touchStart.current = e.changedTouches[0].pageX;
  }, []);

  const handleTouchEnd = useCallback((e) => {
    const touchLength = touchStart.current - e.changedTouches[0].pageX;
    const operation = touchLength < 0 ? 1 : 0;
    const absValue = Math.abs(touchLength);
    if (absValue < 100) return;
    if (operation) {
      handlePrevious();
    } else {
      handleNext();
    }
  }, []);

  useEffect(() => {
    if (!containerRef.current) return;
    containerRef.current.addEventListener("touchstart", handleTouchStart);

    containerRef.current.addEventListener("touchend", handleTouchEnd);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.container} ref={containerRef}>
      <div
        className={styles.slider}
        style={{ transform: `translateX(${sliderTransform}vw)` }}
      >
        <div className={styles.sliderItem}>
          <div className={styles.sliderItemContent}>
            <img
              src={TabletFunc01}
              alt="Funkcja 1"
              className={styles.sliderItemPhoto}
            />
          </div>
        </div>
        <div className={styles.sliderItem}>
          <div className={styles.sliderItemContent}>
            <img
              src={TabletFunc02}
              alt="Funkcja 2"
              className={styles.sliderItemPhoto}
            />
          </div>
        </div>
        <div className={styles.sliderItem}>
          <div className={styles.sliderItemContent}>
            <img
              src={TabletFunc03}
              alt="Funkcja 3"
              className={styles.sliderItemPhoto}
            />
          </div>
        </div>
        <div className={styles.sliderItem}>
          <div className={styles.sliderItemContent}>
            <img
              src={TabletFunc04}
              alt="Funkcja 4"
              className={styles.sliderItemPhoto}
            />
          </div>
        </div>
        <div className={styles.sliderItem}>
          <div className={styles.sliderItemContent}>
            <img
              src={TabletFunc05}
              alt="Funkcja 5"
              className={styles.sliderItemPhoto}
            />
          </div>
        </div>
      </div>
      <div className={styles.progressBar}>
        <div
          className={styles.progressBarIndicator}
          style={{ transform: `translateX(${indicatorTransform}%)` }}
        />
      </div>
      <div
        className={styles.description}
        style={{ transform: `translateX(${sliderTransform}vw)` }}
      >
        <div className={styles.descriptionItem}>
          <div className={styles.descriptionItemContent}>
            <div className={styles.descriptionNumberContainer}>
              <div className={styles.descriptionNumberCircle}>
                <span className={styles.descriptionNumberCircleText}>1</span>
              </div>
              <div className={styles.descriptionNumberEffect} />
            </div>
            <div className={styles.descriptionTitleContainer}>
              <span className={styles.descriptionTitle}>
                KOMUNIKATY DYNAMICZNE
              </span>
            </div>
            <div className={styles.descriptionTextContainer}>
              <span className={styles.descriptionText}>
                System w czasie rzeczywistym analizuje i wyświetla komunikaty z
                sugerowanym zachowaniem celu optymalizacji jazdy.
              </span>
            </div>
          </div>
        </div>
        <div className={styles.descriptionItem}>
          <div className={styles.descriptionItemContent}>
            <div className={styles.descriptionNumberContainer}>
              <div className={styles.descriptionNumberCircle}>
                <span className={styles.descriptionNumberCircleText}>2</span>
              </div>
              <div className={styles.descriptionNumberEffect} />
            </div>
            <div className={styles.descriptionTitleContainer}>
              <span className={styles.descriptionTitle}>PANEL NAWIGACYJNY</span>
            </div>
            <div className={styles.descriptionTextContainer}>
              <span className={styles.descriptionText}>
                Terminal wyposażony jest w dynamiczny panel nawigacyjny
                wskazujący m.in. wskaźniki kolejowe występujące na trasie oraz
                ich odległość od bieżącego położenia.
              </span>
            </div>
          </div>
        </div>
        <div className={styles.descriptionItem}>
          <div className={styles.descriptionItemContent}>
            <div className={styles.descriptionNumberContainer}>
              <div className={styles.descriptionNumberCircle}>
                <span className={styles.descriptionNumberCircleText}>3</span>
              </div>
              <div className={styles.descriptionNumberEffect} />
            </div>
            <div className={styles.descriptionTitleContainer}>
              <span className={styles.descriptionTitle}>
                ESTYMACJA MIEJSCA ZATRZYMANIA CZOŁA POJAZDU
              </span>
            </div>
            <div className={styles.descriptionTextContainer}>
              <span className={styles.descriptionText}>
                System wposażony jest w funkcję przewidywania zatrzymania czoła
                pojazdu w zależności od charakterystyki poruszania pojazdu,
                warunków atmosferycznych i parametrów technicznych pojazdów.
              </span>
            </div>
          </div>
        </div>
        <div className={styles.descriptionItem}>
          <div className={styles.descriptionItemContent}>
            <div className={styles.descriptionNumberContainer}>
              <div className={styles.descriptionNumberCircle}>
                <span className={styles.descriptionNumberCircleText}>4</span>
              </div>
              <div className={styles.descriptionNumberEffect} />
            </div>
            <div className={styles.descriptionTitleContainer}>
              <span className={styles.descriptionTitle}>
                PRZYSPIESZENIE POJAZDU
              </span>
            </div>
            <div className={styles.descriptionTextContainer}>
              <span className={styles.descriptionText}>
                Terminal wyposażony jest w funkcję reprezentacji wizualnej
                informacji o chwilowo zadanej sile.
              </span>
            </div>
          </div>
        </div>
        <div className={styles.descriptionItem}>
          <div className={styles.descriptionItemContent}>
            <div className={styles.descriptionNumberContainer}>
              <div className={styles.descriptionNumberCircle}>
                <span className={styles.descriptionNumberCircleText}>5</span>
              </div>
              <div className={styles.descriptionNumberEffect} />
            </div>
            <div className={styles.descriptionTitleContainer}>
              <span className={styles.descriptionTitle}>
                LICZNIK ECO DRIVINGU
              </span>
            </div>
            <div className={styles.descriptionTextContainer}>
              <span className={styles.descriptionText}>
                System posiada wbudowaną funkcję WAM SCORE, dzięki której w
                przejrzysty sposób możesz obserwować skuteczność swojej
                ekonomicznej jazdy oraz konkurować z innymi użytkownikami
                systemu.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slider;
