import React from "react";
import styles from "./Safety.module.css";
import Graph from "assets/images/graph.png";
import Safe from "assets/images/safe.png";
import ListItem from "./ListItem";
import galaxy from "assets/images/galaxy.png";

const Safety = () => {
  const items = [
    {
      id: 1,
      title: ["ZASTOSUJ ECO DRIVING"],
      icon: Graph,
      description:
        "Moduł Eco Driving analizuje stopień zużycia energii w ujęciu chwilowym oraz sumarycznie dla każdego z przejazdów. Dane dynamiki ruchu pojazdu pochodzące z zamontowanych czujników analizowane są z wykorzystaniem metod uczenia maszynowego. Wytrenowane rekurencyjne sieci neuronowe na bieżące agregują i analizują sygnały w ramach tworzonych szeregów czasowych czego rezultatem są chwilowe wartości zużycia energii.",
    },
    {
      id: 2,
      title: ["Zwiększ Bezpieczeństwo"],
      icon: Safe,
      description:
        "Od 2020 roku w Polsce ilość zdarzeń kolejowych wzrosła o 27%. Wybierz WAM i zadbaj o bezpieczeństwo swojej floty już dziś!",
    },
  ];
  return (
    <div className={styles.container}>
      <div className={styles.container__titleBackground}>
        <div className={styles.container__titleBackground__background}>
          <img
            src={galaxy}
            alt="galaxy"
            className={styles.container__titleBackground__background__img}
          />
        </div>
        <div className={styles.container__titleBackground__title}>
          <h2 className={styles.container__titleBackground__title__head}>
            NOWY WYMIAR <br /> BEZPIECZEŃSTWA
          </h2>
        </div>
      </div>
      <ul className={styles.container__list}>
        {items.map((item) => (
          <ListItem item={item} key={item.id} />
        ))}
      </ul>
    </div>
  );
};

export default Safety;
