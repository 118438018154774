import React, { useEffect, useState } from "react";
import styles from "./Start.module.css";
import { motion } from "framer-motion";
import { useAppStore } from "app/provider/RootStoreProvider";
import {
  buttonStates,
  buttonStatesPortrait,
  textStates,
  textStatesPortrait,
  initalButtonState,
  initalState,
  transition,
  PAGES,
} from "./utils";

const ScreenOne = () => {
  const { state, dispatch } = useAppStore();
  const [textAnimates, setTextAnimates] = useState<any>(
    initalState(state.pageN, state.isPortrait)
  );
  const [buttonAnimates, setButtonAnimates] = useState(
    initalButtonState(state.pageN, state.isPortrait)
  );

  useEffect(() => {
    let textAnimates;
    let buttonAnimates;
    if (!state.isPortrait) {
      textAnimates = textStates(state.page);
      buttonAnimates = buttonStates(state.page);
    } else {
      textAnimates = textStatesPortrait(state.page);
      buttonAnimates = buttonStatesPortrait(state.page);
    }
    setTextAnimates(textAnimates);
    setButtonAnimates(buttonAnimates);
  }, [state.page, state.isPortrait]);

  const render = PAGES.allPages.find((item) => item.name === state.page);
  if (!render) return null;
  return (
    <div className={styles.wrapper}>
      <motion.div
        className={styles.container}
        transition={transition}
        animate={textAnimates}
        initial={initalState(state.pageN, state.isPortrait)}
      >
        <motion.div className={styles.containerItem}>
          <h1 className={styles.title}>
            Wirtualny Asystent
            <br /> Maszynisty
          </h1>
          <p className={styles.textColor}>Z NAWIGATOREM SZLAKU</p>
          <div className={styles.buttonContainer}>
            <motion.div
              key="welcomeTextButton"
              animate={buttonAnimates}
              transition={transition}
              initial={initalButtonState(state.pageN, state.isPortrait)}
            >
              <button
                className={styles.button}
                aria-label="Zobacz demo"
                onClick={() =>
                  dispatch({
                    type: "setPage",
                    payload: { pageName: "video" },
                  })
                }
              >
                ZOBACZ DEMO
              </button>
            </motion.div>
          </div>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default ScreenOne;
