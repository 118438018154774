import { pages } from "app/provider/RootStoreProvider";

export const findPages = (
  pageName: string,
  prevCount: number = 1,
  nextCount: number = 1
) => {
  const pageIndex = pages.findIndex((item) => item.name === pageName);
  const prevPages = [];
  for (let i = pageIndex - prevCount; i < pageIndex; i++) {
    prevPages.push(pages[i] || {});
  }
  const currentPages = [];
  let i = pageIndex;
  while (
    pages[i]?.name?.toLocaleLowerCase().includes(pageName.toLocaleLowerCase())
  ) {
    currentPages.push(pages[i]);
    i++;
  }
  const nextPages = [];
  for (let j = i; j < i + nextCount; j++) {
    nextPages.push(pages[i] || {});
  }
  return {
    prevPages,
    nextPages,
    currentPages,
    allPages: [...prevPages, ...currentPages, ...nextPages],
  };
};

export const findSection = (sectionName: string) => {
  const pageIndex = pages.findIndex(
    (item) => item.friendlyName === sectionName
  );

  const currentPages = [];
  let i = pageIndex;
  while (
    pages[i]?.friendlyName
      ?.toLocaleLowerCase()
      .includes(sectionName.toLocaleLowerCase())
  ) {
    currentPages.push(pages[i]);
    i++;
  }

  return currentPages;
};
