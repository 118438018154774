import React from "react";
import { motion } from "framer-motion";
import styles from "./styles/Background.module.css";
import Header from "components/Desktop/Header/Header";
import colorBackground from "assets/images/colorBackground.png";
import { useAppStore } from "app/provider/RootStoreProvider";

interface IProps {
  children: React.ReactNode[] | React.ReactNode;
  containerRef: any;
}

const Background: React.FC<IProps> = ({ children, containerRef }) => {
  const { state } = useAppStore();
  return (
    <div className={styles.wrapper} ref={containerRef}>
      <Header />
      <div className={styles.wrapperItem}>
        <motion.div className={styles.container}>
          <div className={styles.overlayLayer}>
            {(state.page.includes("tablet") ||
              state.page === "productSlider" ||
              state.page === "reviews" ||
              state.page === "video") && (
              <>
                <div className={styles.imageOverlay}></div>
                <img
                  src={colorBackground}
                  alt="Kolorowe tło"
                  className={styles.overlayImage}
                />
              </>
            )}
          </div>
        </motion.div>
        <div className={styles.childrenContainer}>{children}</div>
      </div>
    </div>
  );
};

export default Background;
