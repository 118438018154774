import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from "./Navbar.module.css";
import { ReactComponent as Logo } from "assets/svgs/wam.svg";
import { ReactComponent as Hamburger } from "assets/svgs/hamburger.svg";
import cx from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { ReactComponent as InfracertSVG } from "assets/svgs/LogoInfracert.svg";
import { FaDollarSign } from "react-icons/fa";
import { GoPlay } from "react-icons/go";

const Navbar = () => {
  const [isNavSticky, setNavSticky] = useState(true);
  const [isNav, setNav] = useState(false);
  const navSticky = useRef(true);

  const handleScroll = useCallback(() => {
    if (window.scrollY > 0 && navSticky.current) {
      navSticky.current = false;
      setNavSticky(false);
    } else if (window.scrollY === 0) {
      navSticky.current = true;
      setNavSticky(true);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div
        className={cx(styles.container, {
          [styles.containerActive]: !isNavSticky,
        })}
      >
        <div className={styles.logo}>
          <Logo />
        </div>
        <div className={styles.menu}>
          <div className={styles.language}>
            <span className={styles.text}>PL</span>
          </div>
          <div className={styles.hamburger}>
            <Hamburger
              className={styles.hamburgerIcon}
              onClick={() => setNav(!isNav)}
            />
          </div>
        </div>
      </div>
      <AnimatePresence>
        {isNav ? (
          <motion.div
            className={cx(styles.mobileNav, {
              [styles.mobileNavActive]: isNav,
            })}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <ul className={styles.mobileList} onClick={() => setNav(false)}>
              <motion.li
                className={styles.mobileListItem}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { delay: 0.8 } }}
                exit={{ opacity: 0, transition: { delay: 0 } }}
              >
                <a href="#start" className={styles.mobileListLink}>
                  Strona główna
                </a>
              </motion.li>
              <motion.li
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { delay: 1 } }}
                exit={{ opacity: 0, transition: { delay: 0 } }}
                className={styles.mobileListItem}
              >
                <a href="#system" className={styles.mobileListLink}>
                  System
                </a>
              </motion.li>
              <motion.li
                className={styles.mobileListItem}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { delay: 1.2 } }}
                exit={{ opacity: 0, transition: { delay: 0 } }}
              >
                <a href="#technology" className={styles.mobileListLink}>
                  Technologia
                </a>
              </motion.li>
              <motion.li
                className={styles.mobileListItem}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { delay: 1.4 } }}
                exit={{ opacity: 0, transition: { delay: 0 } }}
              >
                <a href="#product" className={styles.mobileListLink}>
                  Product
                </a>
              </motion.li>
              <motion.li
                className={styles.mobileListItem}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { delay: 1.6 } }}
                exit={{ opacity: 0, transition: { delay: 0 } }}
              >
                <a href="#contact" className={styles.mobileListLink}>
                  Kontakt
                </a>
              </motion.li>
            </ul>
            <div className={styles.bottom}>
              <motion.div
                className={styles.video}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { delay: 2.5 } }}
                exit={{ opacity: 0, transition: { delay: 0 } }}
              >
                <GoPlay className={styles.playButton} />
              </motion.div>
              <motion.div
                className={styles.mobilelogo}
                initial={{ y: 120 }}
                animate={{ y: 0, transition: { delay: 1.8 } }}
                exit={{ y: 120, transition: { delay: 0 } }}
              >
                <div className={styles.mobilelogoContainer}>
                  <InfracertSVG />
                </div>
              </motion.div>
              <motion.div
                className={styles.price}
                initial={{ y: 120 }}
                animate={{ y: 0, transition: { delay: 2 } }}
                exit={{ y: 120, transition: { delay: 0 } }}
              >
                <div className={styles.dolar}>
                  <FaDollarSign className={styles.dolarIcon} />
                </div>
                <span className={styles.priceText}>
                  Zapytaj <br /> O wycenę
                </span>
              </motion.div>
            </div>
          </motion.div>
        ) : null}
      </AnimatePresence>
    </>
  );
};

export default Navbar;
