import React, { useState } from "react";
import styles from "./WamLongDescription.module.css";
import hands from "assets/images/hands.png";
import hands2560w from "assets/images/hands2560w.png";
import hands1920w from "assets/images/hands1920w.png";
import hands1366w from "assets/images/hands1366w.png";
import hands1024w from "assets/images/hands1024w.png";
import { CgMathMinus, CgMathPlus } from "react-icons/cg";
import { AnimatePresence, motion } from "framer-motion";
import { useAppStore } from "app/provider/RootStoreProvider";
import { findPages } from "app/utils/render";

const PAGES = findPages("wamLongDescription", 1, 1);

const WamLongDescription = () => {
  const { state } = useAppStore();
  const [isFullText, setFullText] = useState(false);
  const shortText = [
    "Wirtualny Asystent Maszynisty to system opracowany w postaci konsoli składający się z trzech głównych komponentów: modułu analitycznego (analizuje otoczenie i dynamikę jazdy w czasie rzeczywistym), terminala (interfejs dla maszynisty) oraz aplikacji zarządzającej (umożliwia dynamiczny przepływ informacji w systemie).",
  ];
  const fullText = [
    "Wirtualny Asystent Maszynisty to system opracowany w postaci konsoli składający się z trzech głównych komponentów: modułu analitycznego (analizuje otoczenie i dynamikę jazdy w czasie rzeczywistym), terminala (interfejs dla maszynisty) oraz aplikacji zarządzającej (umożliwia dynamiczny przepływ informacji w systemie).",
    "Moduł analityczny: analizuje otoczenie i dynamikę jazdy w czasie rzeczywistym za pomocą sensorów umieszczonych w kabinie. Wykorzystuje systemy optyczne z wsparciem AI oraz precyzyjne czujniki położenia.",
    "Terminal WAM: umieszczony w kabinie jest interfejsem dla maszynisty. Przedstawia informacje o szlaku, ostrzeżenia, komunikaty. Umożliwia interaktywną komunikację z asystentem.",
    "Aplikacja zarządzająca: umożliwia dynamiczny przepływ informacji w systemie dzięki czemu maszynista posiada możliwość dostępu do aktualnych, kluczowych informacji z perspektywy przebiegu trasy.",
  ];

  const handleButtonClick = () => {
    setFullText(!isFullText);
  };

  const transition = {
    bounce: 0,
    type: "spring",
    damping: 50,
    stiffness: 95,
  };

  const backgroundAnimates = (state: string, isActive: boolean) => {
    switch (state) {
      case PAGES.currentPages[0].name:
        return {
          opacity: 1,
          bottom: isActive ? "-50%" : "-40%",
        };
      default:
        return {
          opacity: 0,
          bottom: "-50%",
        };
    }
  };

  const textAnimates = (state: string) => {
    switch (state) {
      case PAGES.currentPages[0].name:
        return {
          opacity: 1,
        };
      default:
        return {
          opacity: 0,
        };
    }
  };

  const render = PAGES.allPages.find((item) => item.name === state.page);
  if (!render) return null;
  return (
    <AnimatePresence>
      <div className={styles.container}>
        <motion.div
          className={styles.background}
          animate={backgroundAnimates(state.page, isFullText)}
          initial={{ opacity: 0, bottom: "-40%" }}
          transition={transition}
        >
          <div className={styles.backgroundOverlay} />
          <img
            src={hands1920w}
            alt="dłonie"
            className={styles.backgroundImage}
            srcSet={`${hands} 4000w, ${hands2560w} 2560w, ${hands1920w} 1920w, ${hands1366w} 1366w, ${hands1024w} 1024w `}
          />
        </motion.div>
        <motion.div
          className={styles.content}
          animate={textAnimates(state.page)}
          transition={transition}
          initial={{ opacity: 0 }}
        >
          <h2 className={styles.title}>
            Czym jest <b className={styles.titleBold}>WAM?</b>
          </h2>
          <div className={styles.text}>
            <AnimatePresence>
              {isFullText ? (
                <div className={styles.textContainer}>
                  {fullText.map((item) => (
                    <p className={styles.textParagraph} key={item}>
                      {item}
                    </p>
                  ))}
                </div>
              ) : (
                <div className={styles.textContainer}>
                  {shortText.map((item) => (
                    <p className={styles.textParagraph} key={item}>
                      {item}
                    </p>
                  ))}
                </div>
              )}
            </AnimatePresence>
          </div>
          <div className={styles.action}>
            <button className={styles.button} onClick={handleButtonClick}>
              {isFullText ? (
                <CgMathMinus className={styles.plusIcon} />
              ) : (
                <CgMathPlus className={styles.plusIcon} />
              )}
            </button>
            <button className={styles.helper} onClick={handleButtonClick}>
              {isFullText ? "Pokaż mniej" : "Dowiedz się więcej"}
            </button>
          </div>
        </motion.div>
      </div>
    </AnimatePresence>
  );
};

export default WamLongDescription;
