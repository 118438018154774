import { activeNav, navVerif } from "app/utils/storeUtils";
import React from "react";

const AppContext = React.createContext({});

export const pages = [
  { name: "start", friendlyName: "start", break: 1000, title: "" },
  {
    name: "wamShortDescription",
    friendlyName: "sectionOne",
    break: 1000,
    title: "Poznaj WAM",
  },
  {
    name: "wamLongDescription",
    friendlyName: "sectionOne",
    break: 1000,
    title: "Komponenty systemu",
  },
  {
    name: "terminalPresentation",
    friendlyName: "sectionOne",
    break: 1000,
    title: "Bezpieczeństwo",
  },
  { name: "lights", friendlyName: "sectionOne", break: 1000, title: "D" },
  {
    name: "tabletLeftInfoOne",
    friendlyName: "sectionTwo",
    break: 1000,
    title: "Działanie real time",
  },
  {
    name: "tabletLeftInfoTwo",
    friendlyName: "sectionTwo",
    break: 1000,
    title: "Predykcja niebezpieczeństw",
  },
  {
    name: "tabletLeftInfoThree",
    friendlyName: "sectionTwo",
    break: 1000,
    title: "Biblioteka dokumentów",
  },
  {
    name: "tabletRightInfoOne",
    friendlyName: "sectionTwo",
    break: 1000,
    title: "AI",
  },
  // {
  //   name: "tabletRightInfoTwo",
  //   friendlyName: "sectionTwo",
  //   break: 1000,
  //   title: "I",
  // },
  {
    name: "tabletRightInfoThree",
    friendlyName: "sectionTwo",
    break: 1000,
    title: "Eco driving",
  },
  {
    name: "tabletFunctions",
    friendlyName: "sectionTwo",
    break: 1000,
    title: "Terminal",
  },
  {
    name: "breakingCurve",
    friendlyName: "sectionThree",
    break: 1000,
    title: "Wsparcie",
  },
  { name: "technology", friendlyName: "sectionThree", break: 1000, title: "M" },
  {
    name: "technology.2",
    friendlyName: "sectionThree",
    break: 1000,
    title: "Predykcja",
  },
  {
    name: "productSlider",
    friendlyName: "sectionThree",
    break: 1000,
    title: "Funkcje systemu",
  },
  // { name: "reviews", friendlyName: "sectionThree", break: 1000, title: "" },
  { name: "video", friendlyName: "sectionThree", break: 1000, title: "Demo" },
  {
    name: "contact",
    friendlyName: "sectionFour",
    break: 1000,
    title: "Kontakt",
  },
];

const initialState = {
  page: pages[0].name,
  pageN: 0,
  renderScrollPages: false,
  productPage: false,
  activeNav: null,
  scrollDirection: undefined,
  isScroll: false,
  isPhone: window.innerWidth < 1023,
  isPortrait: window.innerHeight > window.innerWidth,
  isUltraWide: window.innerWidth / window.innerHeight > 2.1,
  friendlyName: pages[0].friendlyName,
};
const productStopPageIndex = pages.findIndex(
  (e) => e.name === "productTrain.1"
);

function reducer(
  state: any = initialState,
  action: { type: string; payload: any }
) {
  switch (action.type) {
    case "nextPage": {
      let newPage = state.pageN;
      if (newPage < pages.length - 1) {
        newPage += 1;
      }
      const newPageName = pages[newPage].name;
      const newPageFriendlyName = pages[newPage].friendlyName;
      if (newPage === state.pageN) return { ...state };
      return {
        ...state,
        page: newPageName,
        friendlyName: newPageFriendlyName,
        scrollDirection: "next",
        pageN: newPage,
        activeNav: activeNav(newPageFriendlyName),
        subActiveNav: newPageFriendlyName,
      };
    }
    case "previousPage": {
      let newPage = state.pageN;
      if (newPage > 0) {
        newPage -= 1;
      }
      const newPageName = pages[newPage].name;
      const newPageFriendlyName = pages[newPage].friendlyName;
      if (newPage === state.pageN) return { ...state };
      return {
        ...state,
        page: newPageName,
        friendlyName: newPageFriendlyName,
        pageN: newPage,
        scrollDirection: "back",
        activeNav: activeNav(newPageFriendlyName),
        subActiveNav: newPageFriendlyName,
      };
    }
    case "setPage": {
      const pageIndex = pages.findIndex(
        (e) => e.name === action.payload.pageName
      );
      if (pageIndex >= pages.length && state.pageN === pages.length - 1) {
        return {
          ...state,
        };
      } else if (pageIndex >= pages.length) {
        return {
          ...state,
          productPage: true,
          pageN: pages.length - 1,
          page: pages[pages.length - 1].name,
          friendlyName: pages[pages.length - 1].friendlyName,
          activeNav: activeNav(pages[pages.length - 1].friendlyName),
          subActiveNav: pages[pages.length - 1].friendlyName,
        };
      }
      return {
        ...state,
        pageN: pageIndex,
        page: action.payload.pageName,
        friendlyName: pages[pageIndex].friendlyName,
        productPage: pageIndex >= productStopPageIndex ? true : false,
        activeNav: activeNav(pages[pageIndex].friendlyName),
        subActiveNav: pages[pageIndex].friendlyName,
      };
    }
    case "setPageHeight": {
      return {
        ...state,
        ...action.payload,
      };
    }
    case "setPortrait": {
      if (state.isPortrait === action.payload.isPortrait) return { ...state };
      return {
        ...state,
        isPortrait: action.payload.isPortrait,
        page: pages[0].name,
        friendlyName: pages[0].friendlyName,
        pageN: 0,
        renderScrollPages: false,
        productPage: false,
        activeNav: null,
        scrollDirection: undefined,
        isScroll: false,
      };
    }
    case "setUltraWide": {
      if (state.isUltraWide === action.payload.isUltraWide) return { ...state };
      return {
        ...state,
        isUltraWide: action.payload.isUltraWide,
        page: pages[0].name,
        friendlyName: pages[0].friendlyName,
        pageN: 0,
        renderScrollPages: false,
        productPage: false,
        activeNav: null,
        scrollDirection: undefined,
        isScroll: false,
      };
    }
    case "setPhone": {
      return {
        ...state,
        isPhone: action.payload.isPhone,
      };
    }
    case "setDesktop": {
      return {
        ...state,
        isUltraWide: false,
        isPhone: false,
      };
    }
    case "setNavActive": {
      if (!navVerif(state.page).includes(action.payload.active)) return state;
      return { ...state, activeNav: action.payload.active };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}

function Provider({
  children,
}: {
  children: React.ReactNode[] | React.ReactNode;
}) {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const value = { state, dispatch };
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

function useAppStore() {
  const context: any = React.useContext(AppContext);
  if (context === undefined) {
    throw new Error("useCount must be used within a CountProvider");
  }
  return context;
}

export { Provider, useAppStore, reducer };
