import React, { useEffect, useState } from "react";
import { useAppStore } from "app/provider/RootStoreProvider";
import styles from "./styles/Marker.module.css";
import { motion } from "framer-motion";
import cx from "classnames";
import { markerStates, markerStatesPortrait, pages } from "./utils";

interface IProps {
  order: number;
  marker: "first" | "second" | "third" | "fourth" | "fifth";
  title: string[];
  texts: string[];
  number: number;
  mobileOrder: number;
}

const Marker: React.FC<IProps> = ({
  order,
  marker,
  title,
  texts,
  number,
  mobileOrder,
}) => {
  const { state } = useAppStore();
  const [markerAnimates, setmarkerAnimates] = useState<any>({});

  const render = pages.allPages.find((item) => item.name === state.page);

  useEffect(() => {
    if (render) {
      let nextState;
      if (state.isPortrait) {
        nextState = markerStatesPortrait(state.page);
      } else nextState = markerStates(state.page);

      setmarkerAnimates(nextState);
    }
  }, [state.page, state.isPortrait, render, order, mobileOrder]);

  const transition = {
    bounce: 0,
    type: "spring",
    damping: 30,
  };

  if (!render) return null;
  return (
    <motion.div
      className={cx(styles.marker, {
        [styles.markerOne]: marker === "first",
        [styles.markerTwo]: marker === "second",
        [styles.markerThree]: marker === "third",
        [styles.markerFour]: marker === "fourth",
        [styles.markerFive]: marker === "fifth",
      })}
      animate={markerAnimates}
      transition={transition}
      initial={{
        opacity: 0,
      }}
    >
      <div className={styles.title}>
        <h1 className={styles.titleName}>
          {title.map((item, index) => (
            <React.Fragment key={item}>
              {item}
              {index !== title.length - 1 ? <br /> : null}
            </React.Fragment>
          ))}
        </h1>
        <div className={styles.titleNumber}>{number}</div>
      </div>
      <div className={styles.text}>
        {texts.map((item) => (
          <p key={item} className={styles.textParagraph}>
            {item}
          </p>
        ))}
      </div>
    </motion.div>
  );
};

export default Marker;
