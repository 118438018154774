import React from "react";
import { ReactComponent as SVG } from "assets/svgs/wam.svg";
import { useAppStore } from "app/provider/RootStoreProvider";
import styles from "./styles/Header.module.css";
import cx from "classnames";
import {
  contactNavClick,
  mainNavClick,
  productNavClick,
  systemNavClick,
  technologyNavClick,
} from "app/utils/nav";
import { motion } from "framer-motion";
import SystemSubNav from "./SystemSubNav";
import TechnologySubNav from "./TechnologySubNav";
import ProductSubNav from "./ProductSubNav";

const Header = () => {
  const { state, dispatch } = useAppStore();

  return (
    <header className={styles.header}>
      <div className={styles.navContainer}>
        <nav className={styles.nav} role="navigation" aria-labelledby="mainnav">
          <ul className={styles.navItems}>
            <li className={styles.navItem}>
              <motion.button
                animate={{
                  color:
                    state.activeNav === "sectionOne"
                      ? "rgb(40, 217, 255)"
                      : "rgb(178, 178, 178)",
                }}
                style={{
                  textShadow:
                    state.activeNav === "sectionOne"
                      ? "1px 1px 4px rgb(40, 217, 255)"
                      : undefined,
                }}
                transition={{
                  duration: 0.3,
                  ease: "easeInOut",
                }}
                className={styles.navItemLink}
                onClick={() => systemNavClick(state.friendlyName, dispatch)}
                aria-label="Przejdź do systemu"
              >
                SYSTEM
              </motion.button>
            </li>
            <li className={styles.navItem}>
              <motion.button
                aria-label="Przejdź do technologi"
                className={styles.navItemLink}
                animate={{
                  color:
                    state.activeNav === "sectionTwo"
                      ? "rgb(40, 217, 255)"
                      : "rgb(178, 178, 178)",
                }}
                style={{
                  textShadow:
                    state.activeNav === "sectionTwo"
                      ? "1px 1px 4px rgb(40, 217, 255)"
                      : undefined,
                }}
                transition={{
                  duration: 0.3,
                  ease: "easeInOut",
                }}
                onClick={() => technologyNavClick(state.friendlyName, dispatch)}
              >
                TECHNOLOGIA
              </motion.button>
            </li>
            <li className={cx(styles.navItem, styles.navItemBrand)}>
              <div className={styles.brandTitle}>
                <button
                  className={styles.brandTitleButton}
                  onClick={() => mainNavClick(state.friendlyName, dispatch)}
                  aria-label="Przejdź do głównej"
                >
                  <SVG />
                </button>
              </div>
            </li>
            <li className={styles.navItem}>
              <motion.button
                className={styles.navItemLink}
                animate={{
                  color:
                    state.activeNav === "sectionThree"
                      ? "rgb(40, 217, 255)"
                      : "rgb(178, 178, 178)",
                }}
                style={{
                  textShadow:
                    state.activeNav === "sectionThree"
                      ? "1px 1px 4px rgb(40, 217, 255)"
                      : undefined,
                }}
                transition={{
                  duration: 0.3,
                  ease: "easeInOut",
                }}
                onClick={() => productNavClick(state.friendlyName, dispatch)}
                aria-label="Przejdź do produktu"
              >
                PRODUKT
              </motion.button>
            </li>
            <li className={styles.navItem}>
              <motion.button
                aria-label="Przejdź do kontaktu"
                animate={{
                  color:
                    state.activeNav === "sectionFour"
                      ? "rgb(40, 217, 255)"
                      : "rgb(178, 178, 178)",
                }}
                style={{
                  textShadow:
                    state.activeNav === "sectionFour"
                      ? "1px 1px 4px rgb(40, 217, 255)"
                      : undefined,
                }}
                transition={{
                  duration: 1,
                  ease: "easeInOut",
                }}
                className={cx(
                  styles.navItemLink,
                  styles.navItemLinkDistinctive
                )}
                onClick={() => contactNavClick(state.friendlyName, dispatch)}
              >
                KONTAKT
              </motion.button>
            </li>
          </ul>
        </nav>
        {state.activeNav === "sectionOne" && <SystemSubNav />}
        {state.activeNav === "sectionTwo" && <TechnologySubNav />}
        {state.activeNav === "sectionThree" && <ProductSubNav />}
      </div>
    </header>
  );
};

export default Header;
