export const activeNav = (sectionName: string) => {
  if (sectionName === "sectionOne") return "sectionOne";
  else if (sectionName === "sectionTwo") return "sectionTwo";
  else if (sectionName === "sectionThree") return "sectionThree";
  else if (sectionName === "sectionFour") return "sectionFour";
  else return null;
};

export const activeSubNav = (pageName: string) => {
  if (pageName.includes("sysInfo"))
    return pageName.substring(3).toLocaleLowerCase();
  else if (pageName.includes("tech"))
    return pageName.substring(4).toLocaleLowerCase();
  else if (pageName.includes("product"))
    return pageName.substring(7).toLocaleLowerCase();
  else return null;
};

export const navVerif = (page: string): string[] => {
  if (page.includes("sys")) return ["system"];
  else if (page.includes("tech")) return ["technology"];
  else if (page === "product") return ["product", "contact"];
  return [];
};
