import React, { useEffect } from "react";
import { useAppStore } from "app/provider/RootStoreProvider";
import DesktopLayout from "layout/DesktopLayout";
import MobileLayout from "layout/MobileLayout";

const App1 = () => {
  const { dispatch, state } = useAppStore();

  const onResize = () => {
    const isPortrait = window.innerHeight > window.innerWidth;
    const isUltraWide = window.innerWidth / window.innerHeight > 2.1;
    const isPhone = window.innerWidth <= 480;
    dispatch({ type: "setPortrait", payload: { isPortrait } });
    if (isUltraWide)
      dispatch({ type: "setUltraWide", payload: { isUltraWide } });
    else if (isPhone) dispatch({ type: "setPhone", payload: { isPhone } });
    else dispatch({ type: "setDesktop" });
  };

  useEffect(() => {
    window.addEventListener("resize", onResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{state.isPhone ? <MobileLayout /> : <DesktopLayout />}</>;
};

export default App1;
