import React, { useState } from "react";
import styles from "./SliderItem.module.css";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import cx from "classnames";

interface IProps {
  width: number;
  image: string;
  description: string;
  title: string;
}

const SliderItem: React.FC<IProps> = ({ width, image, description, title }) => {
  const [isOpen, setOpen] = useState(false);

  const handleButtonClick = () => {
    setOpen(!isOpen);
  };
  return (
    <div
      className={styles.container}
      style={{ "--width": width + "px" } as React.CSSProperties}
    >
      <div className={styles.container__content}>
        <div className={styles.container__content__phtoto}>
          <div
            className={cx(styles.container__content__phtoto__overlay, {
              [styles.container__content__phtoto__overlay__photo_active]:
                !!isOpen,
            })}
          >
            <p
              className={
                styles.container__content__phtoto__overlay__description
              }
            >
              {description}
            </p>
          </div>
          <img
            src={image}
            alt="pociąg"
            className={styles.container__content_phtoto__img}
          />
          {/* <button
            className={styles.container__content__phtoto__button}
            onClick={handleButtonClick}
          >
            {isOpen ? (
              <AiOutlineMinus
                size={22}
                className={styles.container__content__phtoto__button__icon}
              />
            ) : (
              <AiOutlinePlus
                size={22}
                className={styles.container__content__phtoto__button__icon}
              />
            )}
          </button> */}
        </div>
        <div className={styles.container__content__title}>
          <p className={styles.container__content__title__paragraph}>{title}</p>
        </div>
      </div>
    </div>
  );
};

export default SliderItem;
