import React from "react";
import PrimaryInfo from "components/Desktop/Informations/PrimaryInfo/PrimaryInfo";
import styles from "components/Desktop/Informations/styles/Information.module.css";
import { findPages } from "app/utils/render";
import { useAppStore } from "app/provider/RootStoreProvider";

const PAGES = findPages("tabletLeftInfoOne", 2, 2);
const pagesNames = PAGES.allPages.map((item) => item.name);
const textPages: [String, String, String] = [
  pagesNames[0],
  pagesNames[1],
  pagesNames[2],
];
const renderPages: [String, String, String, String] = [
  ...textPages,
  pagesNames[3],
];

const TabletLeftInfoOne = () => {
  const { state } = useAppStore();
  const render = PAGES.allPages.find((item) => item.name === state.page);
  if (!render) return null;
  return (
    <PrimaryInfo
      textStatesPages={textPages}
      textStatesPortraitPage={renderPages}
      renderPages={renderPages}
      container="left"
    >
      <h1 className={styles.title}>AKTUALIZACJA</h1>
      <h1 className={styles.title}>W CZASIE RZECZYWISTYM</h1>
      <div className={styles.subText}>
        <div className={styles.subTextLine}>
          <div className={styles.line} />
        </div>
        <div>
          <p className={styles.subTextParagraph}>
            Informowanie użytkownika o wykrytych i zgłoszonych
          </p>
          <p className={styles.subTextParagraph}>
            niebezpieczeństwo na trasie{" "}
            <span className={styles.underline}>jeszcze nigdy</span>
          </p>
          <p className={styles.subTextParagraph}>
            nie było tak{" "}
            <span className={styles.colorText}>błyskawiczne i precyzyjne.</span>
          </p>
        </div>
      </div>
    </PrimaryInfo>
  );
};

export default TabletLeftInfoOne;
