import styles from "./Contact.module.css";
import { ReactComponent as SVG } from "assets/svgs/wam.svg";
import { FaLinkedinIn, FaFacebookF } from "react-icons/fa";
import { useAppStore } from "app/provider/RootStoreProvider";
import {
  contactNavClick,
  productNavClick,
  systemNavClick,
  technologyNavClick,
} from "app/utils/nav";
import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import cx from "classnames";
import { findPages } from "app/utils/render";
import { ReactComponent as InfracertSvg } from "assets/svgs/LogoInfracert.svg";

const PAGES = findPages("contact", 1, 1);

const Contact = () => {
  const { state, dispatch } = useAppStore();
  const [animates, setAnimates] = useState<any>({
    y: window.innerHeight * 0.92,
    opacity: 0,
  });

  const sectionStates = (page: string) => {
    switch (page) {
      case PAGES.prevPages[0].name: {
        return {
          y: window.innerHeight * 0.92,
          opacity: 0,
        };
      }
      case PAGES.currentPages[0].name: {
        return {
          y: 0,
          opacity: 1,
        };
      }
      default: {
        return {
          opacity: 1,
          y: 0,
        };
      }
    }
  };

  const render = PAGES.allPages.find((item) => item.name === state.page);

  useEffect(() => {
    if (render) {
      const nextState = sectionStates(state.page);
      setAnimates(nextState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.page]);

  if (!render) return null;

  return (
    <motion.div
      className={styles.container}
      animate={animates}
      transition={{
        bounce: 0,
        type: "spring",
        damping: 30,
      }}
      initial={{
        y: window.innerHeight * 0.92,
        opacity: 0,
      }}
    >
      <div className={styles.price}>
        <div className={styles.priceContainer}>
          <div className={styles.priceContainerText}>
            <div className={styles.priceTitle}>
              <h3 className={styles.priceTitleText}>ZAINTERESOWANY?</h3>
              <h3 className={styles.priceTitleText}>
                POPROŚ O OFERTĘ DLA SWOJEJ ORGANIZACJI.
              </h3>
            </div>
            <div className={styles.priceMessageBox}>
              <div className={styles.priceMessageBoxLine} />
              <div className={styles.priceMessageBoxText}>
                <p className={styles.priceMessageBoxTextP}>
                  Interesuje Cię nasz system? Chciałbyś dowiedzieć się więcej?
                </p>
                <p className={styles.priceMessageBoxTextP}>
                  Wypełnij oraz wyślij formularz, a nasz konsultant odezwię się
                  do Ciebie w najbliższym możliwym terminie!
                </p>
              </div>
            </div>
          </div>
          <div className={styles.formContainer}>
            <h2 className={styles.formTitle}>ZAPYTAJ O CENĘ</h2>
            <form
              className={styles.form}
              action="mailto:you@yourdmainhere.com"
              method="post"
            >
              <div className={styles.formField}>
                <div className={styles.formFieldInput}>
                  <label className={styles.formLabel}>Imię</label>
                  <input className={styles.formInput} placeholder="Imię" />
                </div>

                <div className={styles.formFieldInput}>
                  <label className={styles.formLabel}>Firma</label>
                  <input className={styles.formInput} placeholder="Firma" />
                </div>
              </div>
              <div className={styles.formField}>
                <div className={styles.formFieldInput}>
                  <label className={styles.formLabel}>Email</label>
                  <input className={styles.formInput} placeholder="Email" />
                </div>
                <div className={styles.formFieldInput}>
                  <label className={styles.formLabel}>Nr. telefonu</label>
                  <input
                    className={styles.formInput}
                    placeholder="Nr. telefonu"
                  />
                </div>
              </div>
              <div className={styles.formField}>
                <div
                  className={cx(styles.formFieldInput, styles.formFieldArea)}
                >
                  <textarea
                    className={styles.formTextarea}
                    rows={state.isPhone ? 2 : 4}
                    placeholder="Wiadomość"
                  ></textarea>
                </div>
              </div>
              <div className={styles.formButton}>
                <button
                  className={styles.formButtonItem}
                  aria-label="Wycena produktu"
                >
                  Wyślij
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className={styles.contactContainer}>
        <div className={styles.content}>
          <div className={styles.logo}>
            <div className={styles.logoSvg}>
              <SVG />
            </div>
            <div className={styles.logoText}>
              <h1 className={styles.logoTextH1}>
                wirtualny asystent <br />
                maszynisty
              </h1>
            </div>
          </div>
          <div className={styles.contact}>
            <div className={styles.contactGroup}>
              <p className={styles.contactItem}>
                <b>Infracert TSI sp. z o. o.</b>
              </p>
              <p className={styles.contactItem}>+48 22 300 26 38</p>
              <p className={styles.contactItem}>wam@infracert.com</p>
              <p className={styles.contactItem}>ul. Jagiellońska 32/3</p>
              <p className={styles.contactItem}>03-179, Warszawa</p>
              <p className={styles.contactItem}>NIP: PL5272693391</p>
            </div>
          </div>
          <div className={styles.navigation}>
            <button
              className={styles.navigationItem}
              aria-label="przejdź do systemu"
              onClick={() => {
                systemNavClick(state.friendlyName, dispatch);
              }}
              data-active={state.activeNav === "system" ? true : undefined}
            >
              System
            </button>
            <button
              className={styles.navigationItem}
              aria-label="przejdź do technologi"
              onClick={() => technologyNavClick(state.friendlyName, dispatch)}
              data-active={state.activeNav === "technology" ? true : undefined}
            >
              Technologia
            </button>
            <button
              className={styles.navigationItem}
              aria-label="przejdź do produktu"
              onClick={() => productNavClick(state.friendlyName, dispatch)}
              data-active={state.activeNav === "product" ? true : undefined}
            >
              Product
            </button>
            <button
              className={styles.navigationItem}
              aria-label="przejdź do kontaktu"
              data-active={state.activeNav === "contact" ? true : undefined}
              onClick={() => contactNavClick(state.page, dispatch)}
            >
              Kontakt
            </button>
          </div>
          <div className={styles.email}>
            <div className={styles.container__contact__row__logos__infracert}>
              <div className={styles.container__contact__row__logos__wam__logo}>
                <InfracertSvg />
              </div>
            </div>
          </div>
          <div className={styles.socialMedia}>
            <div className={styles.socialMediaIcons}>
              <p className={styles.socialMediaIconsText}>Dołącz do nas na:</p>
              <div className={styles.socialMediaIconsWrapper}>
                <a
                  href="https://pl.linkedin.com/company/infracert-tsi-sp-z-o-o"
                  className={styles.socialMediaIconAnchor}
                >
                  <div className={styles.socialMediaIcon}>
                    <FaLinkedinIn className={styles.socialMediaIconItem} />
                  </div>
                </a>
                <a href="https://www.facebook.com/INFRACERTTSI">
                  <div className={styles.socialMediaIcon}>
                    <FaFacebookF
                      color="rgb(194,194,194)"
                      className={styles.socialMediaIconItem}
                    />
                  </div>
                </a>
              </div>
            </div>

            <div className={styles.socialMediaCopyRight}>
              <p className={styles.socialMediaCopyRightText}>
                Copyright &copy; 2022 WAM
              </p>
              <p className={styles.socialMediaCopyRightText}>
                by Infracert TSI.
              </p>
              <p className={styles.socialMediaCopyRightText}>
                Wszelkie prawa zastrzeżone.
              </p>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Contact;
