import React from "react";
import PrimaryInfo from "components/Desktop/Informations/PrimaryInfo/PrimaryInfo";
import styles from "components/Desktop/Informations/styles/Information.module.css";
import { findPages } from "app/utils/render";
import { useAppStore } from "app/provider/RootStoreProvider";

const PAGES = findPages("tabletRightInfoOne", 2, 2);
const pagesNames = PAGES.allPages.map((item) => item.name);
const textPages: [String, String, String] = [
  pagesNames[0],
  pagesNames[1],
  pagesNames[2],
];
const renderPages: [String, String, String, String] = [
  ...textPages,
  pagesNames[3],
];
const TabletRightInfoOne = () => {
  const { state } = useAppStore();
  const render = PAGES.allPages.find((item) => item.name === state.page);
  if (!render) return null;
  return (
    <PrimaryInfo
      textStatesPages={textPages}
      textStatesPortraitPage={renderPages}
      renderPages={renderPages}
      container="right"
    >
      <h1 className={styles.title}>MODUŁ ANALITYCZNY</h1>
      <div className={styles.subText}>
        <div className={styles.subTextLine}>
          <div className={styles.line} />
        </div>
        <div>
          <p className={styles.subTextParagraph}>
            Wykorzystanie systemów optycznych ze wsparciem AI
          </p>
          <p className={styles.subTextParagraph}>
            oraz precyzyjnych czujników położenia wspomaga
          </p>
          <p className={styles.subTextParagraph}>
            analizę danych w czasie rzeczywistym.
          </p>
        </div>
      </div>
    </PrimaryInfo>
  );
};

export default TabletRightInfoOne;
