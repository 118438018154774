import productSliderOne from "assets/images/productSliderOne.jpg";
import productSliderOne1366w from "assets/images/productSliderOne1366w.jpg";
import productSliderOne1024w from "assets/images/productSliderOne1024w.jpg";
import productSliderOne768w from "assets/images/productSliderOne768w.jpg";
import productSliderOne600w from "assets/images/productSliderOne600w.jpg";
import productSliderOne480w from "assets/images/productSliderOne480w.jpg";
import productSliderOne320w from "assets/images/productSliderOne320w.jpg";
import productSliderTwo1366w from "assets/images/productSliderTwo1366w.jpg";
import productSliderTwo1024w from "assets/images/productSliderTwo1024w.jpg";
import productSliderTwo768w from "assets/images/productSliderTwo768w.jpg";
import productSliderTwo600w from "assets/images/productSliderTwo600w.jpg";
import productSliderTwo480w from "assets/images/productSliderTwo480w.jpg";
import productSliderTwo320w from "assets/images/productSliderTwo320w.jpg";
import productSliderTwo from "assets/images/productSliderTwo.jpg";
import productSliderThree1024w from "assets/images/productSliderThree1024w.jpg";
import productSliderThree768w from "assets/images/productSliderThree768w.jpg";
import productSliderThree600w from "assets/images/productSliderThree600w.jpg";
import productSliderThree480w from "assets/images/productSliderThree480w.jpg";
import productSliderThree320w from "assets/images/productSliderThree320w.jpg";
import styles from "./ProductSlider.module.css";
import { useAppStore } from "app/provider/RootStoreProvider";
import { useState } from "react";
import { motion } from "framer-motion";
import { AiOutlineArrowRight, AiOutlineArrowLeft } from "react-icons/ai";
import { findPages } from "app/utils/render";

const PAGES = findPages("productSlider", 1, 1);

const ProductSlider = () => {
  const { state } = useAppStore();
  const [slideNumber, setSlideNumber] = useState(1);

  const textStates = (page: string) => {
    switch (page) {
      case PAGES.prevPages[0].name: {
        return {
          opacity: 0,
        };
      }

      case PAGES.currentPages[0].name: {
        return {
          opacity: 1,
        };
      }

      default: {
        return {
          opacity: 0,
        };
      }
    }
  };

  const buttonStates = (page: string) => {
    switch (page) {
      case PAGES.prevPages[0].name: {
        return {
          opacity: 0,
        };
      }

      case PAGES.currentPages[0].name: {
        return {
          opacity: 1,
        };
      }

      default: {
        return {
          opacity: 0,
        };
      }
    }
  };

  const imageSection = (page: string, slideNumber: number) => {
    switch (page) {
      case PAGES.prevPages[0].name: {
        return {
          y: 100,
          opacity: 0,
          x: `${(slideNumber - 1) * -20}vw`,
        };
      }
      case PAGES.currentPages[0].name: {
        return {
          y: 0,
          opacity: 1,
          x: `${(slideNumber - 1) * -20}vw`,
        };
      }

      default: {
        return {
          y: 0,
          opacity: 0,
          x: `${(slideNumber - 1) * -20}vw`,
        };
      }
    }
  };

  const render = PAGES.allPages.find((item) => item.name === state.page);

  const transition = {
    bounce: 0,
    type: "spring",
    damping: 42,
    stiffness: 120,
  };

  const onMoveSectionCircleLeft = () => {
    if (slideNumber > 1) {
      setSlideNumber((prev) => prev - 1);
    }
  };

  const onMoveSectionCircleRight = () => {
    if (slideNumber < 5) {
      setSlideNumber((prev) => prev + 1);
    }
  };

  if (!render) return null;

  return (
    <div
      className={styles.container}
      style={{
        zIndex: state.page.includes("productSlider") ? 10 : "initial",
      }}
    >
      <div className={styles.header}>
        <div className={styles.titleContainer}>
          <motion.h1
            className={styles.title}
            transition={transition}
            animate={textStates(state.page)}
            initial={{
              opacity: 0,
            }}
          >
            WSZYSTKO CZEGO POTRZEBUJESZ <br /> W ZASIEGU TWOJEJ RĘKI
          </motion.h1>
        </div>
        <motion.div
          className={styles.moveSection}
          transition={transition}
          animate={buttonStates(state.page)}
          style={{
            cursor:
              state.page === PAGES.currentPages[0].name ? "pointer" : "default",
          }}
          initial={{
            y: state.isPhone ? 0 : 100,
            opacity: 0,
          }}
        >
          <div
            className={styles.moveSectionCircle}
            style={{
              opacity: slideNumber === 1 ? 0.5 : 1,
              cursor: slideNumber === 1 ? "auto" : "pointer",
            }}
            onClick={
              state.page === PAGES.currentPages[0].name
                ? onMoveSectionCircleLeft
                : undefined
            }
          >
            <AiOutlineArrowLeft className={styles.moveSectionIcon} />
          </div>
        </motion.div>
        <motion.div
          className={styles.moveSection}
          transition={transition}
          animate={buttonStates(state.page)}
          style={{
            cursor:
              state.page === PAGES.currentPages[0].name ? "pointer" : "default",
          }}
          initial={{
            y: state.isPhone ? 0 : 100,
            opacity: 0,
          }}
        >
          <div
            className={styles.moveSectionCircle}
            style={{
              opacity: slideNumber === 5 ? 0.5 : 1,
              cursor: slideNumber === 5 ? "auto" : "pointer",
            }}
            onClick={
              state.page === PAGES.currentPages[0].name
                ? onMoveSectionCircleRight
                : undefined
            }
          >
            <AiOutlineArrowRight className={styles.moveSectionIcon} />
          </div>
        </motion.div>
      </div>
      <motion.div
        className={styles.content}
        animate={imageSection(state.page, slideNumber)}
        transition={transition}
        initial={{
          y: 100,
          opacity: 0,
        }}
      >
        <div className={styles.box}>
          <div className={styles.boxImage}>
            <img
              src={productSliderTwo}
              alt="pociąg"
              className={styles.boxImageImg}
              srcSet={`${productSliderTwo} 1920w, ${productSliderTwo1366w} 1366w, ${productSliderTwo1024w} 1024w, ${productSliderTwo768w} 768w, ${productSliderTwo600w} 600w, ${productSliderTwo480w} 480w, ${productSliderTwo320w} 320w`}
            />
          </div>
          <div className={styles.boxText}>
            <h2 className={styles.boxTitle}>
              Dynamiczny asystent z funkcją wirtualnego pilota
            </h2>
          </div>
        </div>
        <div className={styles.box}>
          <div className={styles.boxImage}>
            <img
              src={productSliderTwo}
              alt="pociąg"
              className={styles.boxImageImg}
              sizes="25vw"
              srcSet={`${productSliderThree1024w} 1024w, ${productSliderThree768w} 768w, ${productSliderThree600w} 600w, ${productSliderThree480w} 480w, ${productSliderThree320w} 320w`}
            />
          </div>
          <div className={styles.boxText}>
            <h2 className={styles.boxTitle}>
              Szybkie oznaczanie miejsc na mapie (uszkodzeń, wypadków,
              incydentów)
            </h2>
          </div>
        </div>
        <div className={styles.box}>
          <div className={styles.boxImage}>
            <img
              src={productSliderOne}
              alt="pociąg"
              className={styles.boxImageImg}
              srcSet={`${productSliderOne} 1920w, ${productSliderOne1366w} 1366w, ${productSliderOne1024w} 1024w, ${productSliderOne768w} 768w, ${productSliderOne600w} 600w, ${productSliderOne480w} 480w, ${productSliderOne320w} 320w`}
            />
          </div>
          <div className={styles.boxText}>
            <h2 className={styles.boxTitle}>
              Automatyczna detekcja i identyfikacja wskaźników kolejowych oraz
              elementów infrastruktury
            </h2>
          </div>
        </div>
        <div className={styles.box}>
          <div className={styles.boxImage}>
            <img
              src={productSliderThree1024w}
              alt="pociąg"
              className={styles.boxImageImg}
            />
          </div>
          <div className={styles.boxText}>
            <h2 className={styles.boxTitle}>Moduł Eco Driving</h2>
          </div>
        </div>
        <div className={styles.box}>
          <div className={styles.boxImage}>
            <img
              src={productSliderThree1024w}
              alt="pociąg"
              className={styles.boxImageImg}
            />
          </div>
          <div className={styles.boxText}>
            <h2 className={styles.boxTitle}>Cyfrowa biblioteka dokumentów</h2>
          </div>
        </div>
        <div className={styles.box}>
          <div className={styles.boxImage}>
            <img
              src={productSliderThree1024w}
              alt="pociąg"
              className={styles.boxImageImg}
            />
          </div>
          <div className={styles.boxText}>
            <h2 className={styles.boxTitle}>I wiele więcej...</h2>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default ProductSlider;
