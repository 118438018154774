import { useAppStore } from "app/provider/RootStoreProvider";
import { findPages } from "app/utils/render";
import React from "react";
import styles from "./Technology.module.css";
import technologyBg from "assets/images/technologyBg.jpg";
import technologyBg2560w from "assets/images/technologyBg2560w.jpg";
import technologyBg1920w from "assets/images/technologyBg1920w.jpg";
import technologyBg1366w from "assets/images/technologyBg1366w.jpg";
import technologyBg1024w from "assets/images/technologyBg1024w.jpg";
import technologyBg768w from "assets/images/technologyBg768w.jpg";
import technologyStripes from "assets/images/technologyStripes.png";
import { motion } from "framer-motion";
import cx from "classnames";

const PAGES = findPages("technology", 1, 1);

const Technology = () => {
  const { state } = useAppStore();
  if (!PAGES.allPages.find((item) => item.name === state.page)) return null;

  const headerAnimates = (page: string) => {
    switch (page) {
      case PAGES.currentPages[0].name: {
        return {
          y: 0,
        };
      }
      case PAGES.currentPages[1].name: {
        return {
          y: -50,
        };
      }
      default: {
        return {
          y: 0,
        };
      }
    }
  };

  const descriptionAnimates = (page: string) => {
    switch (page) {
      case PAGES.currentPages[0].name: {
        return {
          y: -100,
          opacity: 0,
        };
      }
      case PAGES.currentPages[1].name: {
        return {
          y: -50,
          opacity: 1,
        };
      }
      default: {
        return {
          y: 0,
          opacity: 0,
        };
      }
    }
  };

  const containerAnimates = (page: string) => {
    switch (page) {
      case PAGES.prevPages[0].name: {
        return { opacity: 0 };
      }
      case PAGES.currentPages[0].name: {
        return {
          opacity: 1,
        };
      }
      case PAGES.currentPages[1].name: {
        return {
          opacity: 1,
        };
      }
      default: {
        return {
          opacity: 0,
        };
      }
    }
  };

  const transition = {
    bounce: 0,
    type: "spring",
    damping: 50,
    stiffness: 100,
  };
  const render = PAGES.allPages.find((item) => item.name === state.page);
  if (!render) return null;
  return (
    <div className={styles.container}>
      <motion.div
        className={styles.content}
        animate={containerAnimates(state.page)}
        transition={transition}
        initial={{ opacity: 0 }}
      >
        <div
          className={cx(styles.background, {
            [styles.backgroundBlur]: PAGES.currentPages[1].name === state.page,
          })}
        >
          <div className={styles.backgroundOverlay} />
          <img
            src={technologyBg}
            srcSet={`${technologyBg} 3840w, ${technologyBg2560w} 2560w, ${technologyBg1920w} 1920w, ${technologyBg1366w} 1366w, ${technologyBg1024w} 1024w, ${technologyBg768w} 768w`}
            sizes="100vw"
            alt="pociąg"
            className={styles.backgroundImage}
          />
          <div className={styles.stripesOverlay}>
            <img
              src={technologyStripes}
              alt="kolorowe paski"
              className={styles.stripesOverlayImg}
            />
          </div>
        </div>

        <div className={styles.blurOverlay} />
        <div className={styles.text}>
          <motion.div
            className="header"
            transition={transition}
            animate={headerAnimates(state.page)}
          >
            <h3 className={styles.subTitle}>Technologia</h3>
            <h1 className={styles.title}>
              OTWIERA OCZY <br /> JESZCZE SZRZEJ
            </h1>
          </motion.div>
          <motion.div
            className={styles.descriptionCollapse}
            transition={transition}
            animate={descriptionAnimates(state.page)}
          >
            <p className={styles.description}>
              System Wirtualnego Asystenta Maszynisty wyposażony jest w funkcję
              rozpoznawania i predykcji znaczników kolejowych.
            </p>
            <p className={styles.description}>
              Nasza technologia pomaga maszynistą dostrzegać na trasach to, co
              jest kluczowe dla bezpieczeństwa transportu kolejowego.
            </p>
            <p className={styles.description}>
              WAM wychwytuje, wskazuje i informuje użytkownika o zbliżających
              się znacznikach na trasie, pokazuje dokładną lokalizację,
              odległość
            </p>
            <p className={styles.description}>
              i reprezentację wizualną znaku dzięki czemu maszynista może mieć
              pewność, że nie przeoczy kluczowych elementów trasy
            </p>
          </motion.div>
        </div>
      </motion.div>
    </div>
  );
};

export default Technology;
