import React from "react";
import styles from "./Header.module.css";
import stripes from "assets/images/stripesMobile.jpg";

const Header = () => {
  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <h1 className={styles.title}>FUNKCJE SYSTEMU</h1>
      </div>
      <div className={styles.stripes}>
        <img src={stripes} alt="paski" className={styles.stripesImg} />
      </div>
    </div>
  );
};

export default Header;
