import { AnimatePresence, motion } from "framer-motion";
import styles from "./styles/Tablet.module.css";
import { pages, useAppStore } from "app/provider/RootStoreProvider";
import tablet from "assets/images/tablet.png";
import tablet2560w from "assets/images/tablet2560w.png";
import tablet1920w from "assets/images/tablet1920w.png";
import tablet1366w from "assets/images/tablet1366w.png";
import tablet1024w from "assets/images/tablet1024w.png";
import tablet768w from "assets/images/tablet768w.png";
import tabletFunctions from "assets/images/tabletFunctions.png";
import tabletFunctions2560w from "assets/images/tabletFunctions2560w.png";
import tabletFunctions1920w from "assets/images/tabletFunctions1920w.png";
import tabletFunctions1366w from "assets/images/tabletFunctions1366w.png";
import tabletFunctions1024w from "assets/images/tabletFunctions1024w.png";
import tabletFunctions768w from "assets/images/tabletFunctions768w.png";
import tabletFunctions600w from "assets/images/tabletFunctions600w.png";
import tabletFunctionsBg from "assets/images/tabletFunctionsBg.png";
import tabletFunctionsBg1920w from "assets/images/tabletFunctionsBg1920w.png";
import tabletFunctionsBg1366w from "assets/images/tabletFunctionsBg1366w.png";
import tabletFunctionsBg1024w from "assets/images/tabletFunctionsBg1024w.png";
import tabletFunctionsBg768w from "assets/images/tabletFunctionsBg768w.png";
import tabletFunctionsBg600w from "assets/images/tabletFunctionsBg600w.png";
import tabletFunctionsBg480w from "assets/images/tabletFunctionsBg480w.png";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import {
  containerTabletState,
  containerTabletStatePortrait,
  tabletStates,
  techFuncOverlay,
} from "./utils";
import Marker from "./Marker/Marker";
import cx from "classnames";
import { findPages } from "app/utils/render";

const functionPages = findPages("tabletFunctions", 1, 1);

const initalState = (page: number, isPortrait: boolean) => {
  if (isPortrait) {
    if (page === 0) {
      return {
        right: "50%",
        x: "50%",
        y: 0,
        bottom: "7.5%",
      };
    }
    return {
      right: "50%",
      bottom: "100%",
      x: "50%",
      y: "0%",
      scale: 0.85,
    };
  } else if (page === 0) {
    return {
      right: "50%",
      bottom: "8%",
      y: "50%",
      x: "50%",
    };
  }
  return {
    right: "50%",
    x: "50%",
    y: 0,
    bottom: "100%",
  };
};

const Tablet = () => {
  const { state } = useAppStore();
  const [containerStyles, setContainerStyles] = useState<any>(
    initalState(state.pageN, state.isPortrait)
  );

  const tabletContainerRef = useRef<HTMLDivElement>(null);

  const transition = {
    bounce: 0,
    type: "spring",
    damping: 50,
    stiffness: 95,
  };

  useEffect(() => {
    let containerStyles;
    if (!state.isPortrait) {
      containerStyles = containerTabletState(
        state.page,
        state.isUltraWide,
        !state.isPortrait || state.isPhone
      );
    } else {
      containerStyles = containerTabletStatePortrait(state.page);
    }
    setContainerStyles(containerStyles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.page, state.isPortrait, state.isUltraWide]);

  return (
    <motion.div
      className={styles.wrapper}
      transition={transition}
      animate={containerStyles}
      initial={initalState(state.pageN, state.isPortrait)}
      ref={tabletContainerRef}
    >
      <div className={styles.markerContainer}>
        <Marker
          title={["PANEL", "NAWIGACYJNY"]}
          number={2}
          texts={[
            "Terminal wyposażony jest w dynamiczny",
            "panel nawigacyjny wskazujący m.in.",
            "wskaźniki kolejowe występujące na trasie",
            "oraz ich odległość od bieżącego położenia.",
          ]}
          marker="first"
          order={3}
          mobileOrder={4}
        />

        <Marker
          title={["KOMUNIKATY", "DYNAMICZNE"]}
          number={1}
          texts={[
            "System w czasie rzeczywistym analizuje",
            "i wyświetla komunikaty z sugerowanym",
            "zachowaniem celu optymalizacji jazdy.",
          ]}
          marker="second"
          order={2}
          mobileOrder={2}
        />

        <Marker
          number={3}
          title={["ESTYMACJA MIEJSCA", "ZATRZYMANIA CZOŁA POJAZDU"]}
          texts={[
            "System wposażony jest w funkcję przewidywania zatrzymania czoła pojazdu",
            "w zależności od charakterystyki poruszania pojazdu, warunków atmosferycznych",
            "i parametrów technicznych pojazdu.",
          ]}
          marker="third"
          order={1}
          mobileOrder={1}
        />
        <Marker
          title={["PRZYSPIESZENIE", "POJAZDU"]}
          number={4}
          texts={[
            "Terminal wyposażony jest w funkcję",
            "reprezentacji wizualnej informacji",
            "o chwilowo zadanej sile.",
          ]}
          marker="fourth"
          order={2}
          mobileOrder={3}
        />
        <Marker
          number={5}
          title={["LICZNIK", "ECO DRIVINGU"]}
          texts={[
            "System posiada wbudowaną funkcję",
            "WAM SCORE, dzięki której w przejrzysty",
            "sposób możesz obserwować skuteczność",
            "swojej ekonomicznej jazdy oraz konkurować",
            "z innymi użytkownikami systemu.",
          ]}
          marker="fifth"
          order={3}
          mobileOrder={5}
        />
      </div>
      <motion.div className={styles.tabletContainer}>
        <motion.div
          className={styles.container}
          animate={state.isPortrait ? {} : tabletStates(state.page)}
          transition={transition}
        >
          {state.page === pages[0].name ? (
            <div className={styles.tabletOverlay} />
          ) : null}
          {state.page === "tabletFunctions" ? (
            <AnimatePresence>
              <motion.div
                className={styles.tabletBackground}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{
                  duration: 1,
                }}
              >
                <img
                  src={tabletFunctionsBg}
                  srcSet={`${tabletFunctionsBg} 2200w, ${tabletFunctionsBg1920w} 1920w, ${tabletFunctionsBg1366w} 1366w, ${tabletFunctionsBg1024w} 1024w, ${tabletFunctionsBg768w} 768w, ${tabletFunctionsBg600w} 600w, ${tabletFunctionsBg480w} 480w`}
                  alt="kolorowe tło"
                  sizes="35vw"
                  className={styles.tabletBackgroundImg}
                />
              </motion.div>
            </AnimatePresence>
          ) : null}
          <img
            srcSet={` ${tablet} 3352w, ${tablet2560w} 2560w, ${tablet1920w} 1920w, ${tablet1366w} 1366w, ${tablet1024w} 1024w, ${tablet768w} 768w`}
            sizes="48vw"
            src={tablet1920w}
            className={styles.image}
            alt="tablet"
          />

          {functionPages.allPages.find((item) => item.name === state.page) && (
            <div
              className={cx(
                styles.imageOverlayContainer,
                styles.imageOverlayContainerMobile
              )}
            >
              <motion.img
                transition={transition}
                src={tabletFunctions}
                sizes="38vw"
                srcSet={`${tabletFunctions} 3352w, ${tabletFunctions2560w} 2560w, ${tabletFunctions1920w} 1920w, ${tabletFunctions1366w} 1366w, ${tabletFunctions1024w} 1024w, ${tabletFunctions768w} 768w, ${tabletFunctions600w} 600w`}
                alt=""
                className={styles.imageOverlay}
                animate={techFuncOverlay(state.page, state.isPortrait)}
                initial={{ opacity: 0 }}
              />
            </div>
          )}
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

export default Tablet;
