import React from "react";
import styles from "./Content.module.css";
import Graph800w from "assets/images/Graph800w.png";
import Graph550w from "assets/images/Graph550w.png";

const Content = () => {
  return (
    <div className={styles.container}>
      <div className={styles.photo}>
        <div className={styles.photoImgContainer}>
          <img
            className={styles.photoImg}
            srcSet={`${Graph800w} 820w, ${Graph550w} 550w`}
            sizes="80vw"
            src={styles.photoImg}
            alt="wykres"
          />
        </div>
        <div className={styles.photoBackground} />
      </div>
      <div className={styles.textContainer}>
        <span className={styles.text}>
          Moduł wsparcia procesu hamowania i przyspieszania, dynamicznie
          wyświetla wzorcową krzywą hamowania w zależności od rodzaju pojazdu
          trakcyjnego, aktualnej prędkości, stanu taboru i infrastruktury,
          warunków pogodowych. System również prezentuje na kokpicie WAM krzywą
          eksploatacyjną, która jest efektem rejstracji różnych charakterystyk
          hamowania maszynistów, również przy uwzględnieniu tych samych warunków
          jazdy. Moduł wspiera również maszynistę w precyzyjnym zatrzymaniu
          pojazdu trakcyjnego w obrębie stacji.
        </span>
      </div>
    </div>
  );
};

export default Content;
