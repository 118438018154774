import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from "./Description.module.css";
import MobileDescriptionOne1000w from "assets/images/MobileDescriptionOne1000w.png";
import MobileDescriptionOne750w from "assets/images/MobileDescriptionOne750w.png";
import MobileDescriptionOne500w from "assets/images/MobileDescriptionOne500w.png";
import MobileDescriptionTwo770w from "assets/images/MobileDescriptionTwo770w.png";
import MobileDescriptionTwo550w from "assets/images/MobileDescriptionTwo550w.png";
import MobileDescriptionThree from "assets/images/MobileDescriptionThree.png";
import MobileDescriptionFour1000w from "assets/images/MobileDescriptionFour1000w.png";
import MobileDescriptionFour750w from "assets/images/MobileDescriptionFour750w.png";
import MobileDescriptionFour500w from "assets/images/MobileDescriptionFour500w.png";

const Description = () => {
  const [indicatorTransform, setIndicatorTransform] = useState(0);
  const [cardTransform, setCardTransform] = useState(0);
  const cardNumber = useRef(0);
  const touchStart = useRef(0);

  const cardContainerRef = useRef<HTMLDivElement>(null);

  const handleNext = () => {
    if (cardNumber.current === 3) return;
    cardNumber.current += 1;
    setIndicatorTransform((prev) => prev + 100);
    setCardTransform((prev) => prev - 100);
  };

  const handlePrevious = () => {
    if (cardNumber.current === 0) return;
    cardNumber.current -= 1;
    setIndicatorTransform((prev) => prev - 100);
    setCardTransform((prev) => prev + 100);
  };

  const handleTouchStart = useCallback((e) => {
    touchStart.current = e.changedTouches[0].pageX;
  }, []);

  const handleTouchEnd = useCallback((e) => {
    const touchLength = touchStart.current - e.changedTouches[0].pageX;
    const operation = touchLength < 0 ? 1 : 0;
    const absValue = Math.abs(touchLength);
    if (absValue < 100) return;
    if (operation) {
      handlePrevious();
    } else {
      handleNext();
    }
  }, []);

  useEffect(() => {
    if (!cardContainerRef.current) return;
    cardContainerRef.current.addEventListener("touchstart", handleTouchStart);

    cardContainerRef.current.addEventListener("touchend", handleTouchEnd);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className={styles.container} id="system">
      <div className={styles.containerContent}>
        <div
          className={styles.cardContainer}
          ref={cardContainerRef}
          style={{ transform: `translateX(${cardTransform}vw)` }}
        >
          <div className={styles.cardItemWrapper}>
            <div className={styles.cardItem}>
              <div className={styles.cardPhoto}>
                <img
                  srcSet={`${MobileDescriptionOne1000w} 1000w, ${MobileDescriptionOne750w} 750w, ${MobileDescriptionOne500w} 500w`}
                  sizes="75vw"
                  alt="world"
                  className={styles.cardPhotoImg}
                />
              </div>
              <div className={styles.cardContent}>
                <h1 className={styles.cardTitle}>
                  AKTUALIZACJA <br /> W CZASIE RZECZYWISTYM
                </h1>
                <span className={styles.cardText}>
                  Informowanie użytkownika o wykrytych <br /> i zgłoszonych
                  niebezpieczeństwach na <br /> trasie jeszcze nigdy nie było
                  tak <br />
                  <span className={styles.cardTextColor}>
                    błyskawiczne i precyzyjne
                  </span>
                  .
                </span>
              </div>
            </div>
          </div>
          <div className={styles.cardItemWrapper}>
            <div className={styles.cardItem}>
              <div className={styles.cardPhoto}>
                <img
                  srcSet={`${MobileDescriptionTwo770w} 770w,  ${MobileDescriptionTwo550w} 550w`}
                  sizes="75vw"
                  alt="world"
                  className={styles.cardPhotoImg}
                />
              </div>
              <div className={styles.cardContent}>
                <h1 className={styles.cardTitle}>
                  SYSTEM PREDYKCJI <br /> NIEBEZPIECZEŃSTW <br /> NA SZLAKACH
                </h1>
                <span className={styles.cardText}>
                  System informuje maszynistę o wszystkich niezbędnych
                  elementach na trasie i występujących niebezpieczeństwach w
                  postaci głosowej.
                </span>
              </div>
            </div>
          </div>
          <div className={styles.cardItemWrapper}>
            <div className={styles.cardItem}>
              <div className={styles.cardPhoto}>
                <img
                  src={MobileDescriptionThree}
                  alt="world"
                  className={styles.cardPhotoImg}
                />
              </div>
              <div className={styles.cardContent}>
                <h1 className={styles.cardTitle}>
                  KOMPLEKSOWY SYSTEM <br /> DOKUMENTACJI <br /> I KOMUNIKACJI
                </h1>
                <span className={styles.cardText}>
                  System w pełni cyfryzuje analogowy przepływ dokumentów,
                  ponadto zapewnia dostęp do bazy wiedzy przewoźnika, w tym
                  dokumentacji technicznych taboru, instrukcji czy procedur.
                </span>
              </div>
            </div>
          </div>
          <div className={styles.cardItemWrapper}>
            <div className={styles.cardItem}>
              <div className={styles.cardPhoto}>
                <img
                  srcSet={`${MobileDescriptionFour1000w} 1000w, ${MobileDescriptionFour750w} 750w, ${MobileDescriptionFour500w} 500w`}
                  sizes="75vw"
                  alt="neurons"
                  className={styles.cardPhotoImg}
                />
              </div>
              <div className={styles.cardContent}>
                <h1 className={styles.cardTitle}>MODUŁ ANALITYCZNY</h1>
                <span className={styles.cardText}>
                  Wykorzystanie systemów optycznych ze wsparciem AI oraz
                  precyzyjnych czujników położenia wspomaga analizę danych w
                  czasie rzeczywistym.
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.sliderProgress}>
          <div
            className={styles.sliderIndicator}
            style={{ transform: `translateX(${indicatorTransform}%)` }}
          />
        </div>
      </div>
    </section>
  );
};

export default Description;
