import React from "react";
import Content from "./Content/Content";
import Header from "./Header/Header";
import styles from "./Graph.module.css";
import stripes from "assets/images/stripesMobile.jpg";

const Graph = () => {
  return (
    <section className={styles.container}>
      <div className={styles.stripe}>
        <img src={stripes} alt="paski" className={styles.stripeImg} />
      </div>
      <div>
        <Header />
      </div>
      <div>
        <Content />
      </div>
    </section>
  );
};

export default Graph;
