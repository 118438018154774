import React from "react";
import styles from "./Contact.module.css";
import { ReactComponent as WAMSvg } from "assets/svgs/wam.svg";
import { ReactComponent as InfracertSvg } from "assets/svgs/LogoInfracert.svg";

const Contact = () => {
  return (
    <footer className={styles.container} id="contact">
      <div className={styles.container__email}>
        <div className={styles.container__email__title}>
          <h2 className={styles.container__email__title__heading}>
            ZAINTERESOWANY? <br /> POPROŚ O OFERTĘ DLA SWOJEJ ORGANIZACJI.
          </h2>
        </div>
        <div className={styles.container__email__description}>
          <p className={styles.container__email__description__paragraph}>
            Interesuje Cię nasz system?
          </p>
          <p className={styles.container__email__description__paragraph}>
            Chciałbyś dowiedzieć się więcej?
          </p>
          <p className={styles.container__email__description__paragraph}>
            Wpisz swój email poniżej, a nasz
          </p>
          <p className={styles.container__email__description__paragraph}>
            konsultat odezwie się do Ciebie
          </p>
          <p className={styles.container__email__description__paragraph}>
            w najbliższym możliwym terminie!
          </p>
        </div>
        <form className={styles.container__email__form}>
          <div className={styles.container__email__form__inputField}>
            <input
              type="text"
              placeholder="Twój email"
              className={styles.container__email__form__inputField__input}
            />
          </div>
          <button className={styles.container__email__form__button}>
            Wyślij
          </button>
        </form>
      </div>
      <div className={styles.container__contact}>
        <div className={styles.container__contact__row}>
          <div className={styles.container__contact__row__nav}>
            <nav className={styles.container__contact__row__nav__container}>
              <ul
                className={styles.container__contact__row__nav__container__list}
              >
                <li
                  className={
                    styles.container__contact__row__nav__container__list__item
                  }
                >
                  <a
                    className={
                      styles.container__contact__row__nav__container__list__item__link
                    }
                    href="#system"
                  >
                    System
                  </a>
                </li>
                <li
                  className={
                    styles.container__contact__row__nav__container__list__item
                  }
                >
                  <a
                    className={
                      styles.container__contact__row__nav__container__list__item__link
                    }
                    href="#technology"
                  >
                    Technologia
                  </a>
                </li>
                <li
                  className={
                    styles.container__contact__row__nav__container__list__item
                  }
                >
                  <a
                    className={
                      styles.container__contact__row__nav__container__list__item__link
                    }
                    href="#product"
                  >
                    Product
                  </a>
                </li>
                <li
                  className={
                    styles.container__contact__row__nav__container__list__item
                  }
                >
                  <a
                    className={
                      styles.container__contact__row__nav__container__list__item__link
                    }
                    href="#contact"
                  >
                    Kontakt
                  </a>
                </li>
              </ul>
            </nav>
          </div>
          <div className={styles.container__contact__row__logos}>
            <div className={styles.container__contact__row__logos__wam}>
              <div className={styles.container__contact__row__logos__wam__logo}>
                <WAMSvg />
              </div>
              <p className={styles.container__contact__row__logos__wam__text}>
                wirtualny asystent
              </p>
              <p className={styles.container__contact__row__logos__wam__text}>
                maszynisty
              </p>
            </div>

            <div className={styles.container__contact__row__logos__infracert}>
              <div className={styles.container__contact__row__logos__wam__logo}>
                <InfracertSvg />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.container__contact__row}>
          <div>
            <p className={styles.container__contact__row__item}>
              <b>Infracert TSI sp. z o. o.</b>
            </p>
            <p className={styles.container__contact__row__item}>
              +48 22 300 26 38
            </p>
            <p className={styles.container__contact__row__item}>
              wam@infracert.com
            </p>
          </div>
          <div>
            <p className={styles.container__contact__row__item}>
              ul. Jagiellońska 32/3
            </p>
            <p className={styles.container__contact__row__item}>
              03-179, Warszawa
            </p>
            <p className={styles.container__contact__row__item}>
              NIP: PL5272693391
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Contact;
