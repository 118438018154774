import React from "react";
import Header from "./Header/Header";
import Slider from "./Slider/Slider";
import styles from "./Functions.module.css";

const Functions = () => {
  return (
    <div>
      <div>
        <Header />
      </div>
      <div className={styles.stripesContainer} id="product">
        <Slider />
      </div>
    </div>
  );
};

export default Functions;
