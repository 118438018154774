import { useAppStore } from "app/provider/RootStoreProvider";
import React, { useEffect, useState } from "react";
import styles from "../styles/Information.module.css";
import cx from "classnames";
import { motion } from "framer-motion";

interface IProps {
  textStatesPages: [String, String, String];
  textStatesPortraitPage:
    | [String, String, String]
    | [String, String, String, String];
  renderPages: String[];
  children: React.ReactNode | React.ReactNode[];
  container: "left" | "right";
}

const PrimaryInfo: React.FC<IProps> = ({
  textStatesPages,
  textStatesPortraitPage,
  renderPages,
  children,
  container,
}) => {
  const { state } = useAppStore();
  const [animates, setAnimates] = useState<any>({
    y: "50%",
    opacity: 0,
    bottom: "-20%",
  });

  const textAnimates = (page: string) => {
    switch (page) {
      case textStatesPages[0]: {
        return {
          y: "50%",
          opacity: 0,
          bottom: "-20%",
          x: "-50%",
        };
      }

      case textStatesPages[1]: {
        return {
          y: "50%",
          opacity: 0,
          bottom: `-15%`,
          x: "-50%",
        };
      }

      case textStatesPages[2]: {
        return {
          opacity: 1,
          bottom: `50%`,
          y: "50%",
          x: "-50%",
        };
      }
      default: {
        return {
          bottom: "120%",
          opacity: 0,
          y: "50%",
          x: "-50%",
        };
      }
    }
  };

  const textAnimatesPortrait = (page: string) => {
    switch (page) {
      case textStatesPortraitPage[0]: {
        return {
          y: "50%",
          bottom: "-20%",
          x: "-50%",
          scale: 0.75,
          opacity: 0,
        };
      }
      case textStatesPortraitPage[1]: {
        return {
          y: "50%",
          bottom: "20%",
          x: "-50%",
          opacity: 0.25,
          scale: 0.75,
        };
      }
      case textStatesPortraitPage[2]: {
        return {
          opacity: 1,
          bottom: "50%",
          y: "50%",
          x: "-50%",
        };
      }
      case textStatesPortraitPage[3]: {
        return {
          bottom: `80%`,
          y: "50%",
          x: "-50%",
          opacity: 0.25,
          scale: 0.75,
        };
      }
      default: {
        return {
          bottom: "120%",
          opacity: 0,
          y: "50%",
          x: "-50%",
          scale: 0.75,
        };
      }
    }
  };

  const render = renderPages.includes(state.page);

  useEffect(() => {
    if (!render) return;
    let nextState;
    if (state.isPortrait) nextState = textAnimatesPortrait(state.page);
    else nextState = textAnimates(state.page);
    setAnimates(nextState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.page, state.isPortrait, render]);

  if (!render) return null;

  return (
    <div>
      <motion.div
        className={cx(styles.container, {
          [styles.containerLeft]: container === "left",
          [styles.containerRight]: container === "right",
        })}
        animate={animates}
        transition={{
          bounce: 0,
          type: "spring",
          damping: 50,
          stiffness: 100,
        }}
        initial={{
          y: "50%",
          opacity: 0,
          bottom: "-20%",
        }}
      >
        {children}
      </motion.div>
    </div>
  );
};

export default PrimaryInfo;
